<template>
    <div class="card a-list" :class="[{
            'is-invalid': invalid,
            'defendant-card': type == 'defendant',
            'accounting-card': type == 'accounting',
            'expanded': type == 'accounting' && more_details,
            'auto-request': type == 'auto-request',
            'checked': checked,
        }, list_type, a_class]">
        <div class="card-body" :class="{'auto-request': type == 'auto-request', 'modified-cases': type == 'modified-cases'}">
            <template v-if="type == 'small'">
                <div @click="$emit('click')">
                    <div class="img-container">
                        <img :src="campaign_img" :alt="campaign">
                    </div>
                    <h2>{{ campaign }}</h2>
                </div>
            </template>
            <template v-else-if="type == 'history'">
                <div class="img-container">
                    <img :src="action_icon" :alt="action">
                </div>
                <div class="a-history-con">
                    <p class="text-primary">{{ subject }} <span class="action-word text-body">{{ user_action ? user_action : action_word }}</span> {{ object }}</p>
                    <p class="additional-details" v-if="isHtml" v-html="details"></p>
                    <p class="additional-details" v-else>{{ details }}</p>
                    <div class="list-footer">
                        <p class="list-footer-status" v-html="status"></p>
                        <p class="list-footer-date">{{ date }}</p>
                    </div>
                </div>
            </template>
            <template v-else-if="type == 'case'">
                <div class="img-container img-container-case animated fadeIn slow" :style="((access.amounts && access.defaults) || access_level() == 7) ? 'height: 160px;' : ''">
                    <ar-checkbox v-if="has_checkbox" :id="rid" name="listings" @click="selected_function(index)"></ar-checkbox>
                    <slot></slot>
                    <div 
                        class="img-box"
                        v-for="(img, index) in filtered_case_imgs"
                        :key="index"
                        :class="[
                            {'img-box-1': [0, 1].includes(case_imgs.length)},
                            {'img-box-2': case_imgs.length == 2},
                            {'img-box-3': case_imgs.length == 3}
                        ]"
                    >
                        <div :key="case_img_key">
                            <img v-lazy="casePreview(img)" class="animated fadeIn slower" :onerror="`this.src='${errorPreview}'`" :alt="details">
                            <template v-if="index == 2 && total_defendants > 4">
                                <div class="case-image-addon animated fadeIn slower">
                                    +{{ total_defendants - 4 }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="seller-info case-info">
                    <div class="d-inline-flex align-items-center mb-1">
                        <b class="position-relative d-block mr-2" style="top: 1px; color: #3B5998; font-size: 15px;">Case Number:</b>
                        <h2 class="mt-0 mb-0 mr-2 d-inline-block text-truncate" :title="case_name" id="case--name" @click="$emit('click')">{{ case_name }}</h2>
                        <div class="position-relative d-block" id="case--statuses" style="top: 3px; z-index: 5">
                            <span :class="`case-status text-uppercase info-status mb-0 status-${c_status.value} ml-2`" style="z-index: 6">Case Status<template v-if="c_status.value">: <b>{{ c_status.label?.toLowerCase() == "case terminated" ? "Case Closed" : c_status.label }}</b></template></span>
    
                            <a :target="file?.link ? '_blank' : null" v-for="(file, index) in case_files" :key="`${case_name}--case-file--${index}`" :href="file?.link ? file.link : 'javascript:void(0)'" class="position-relative" :style="`z-index: ${case_files.length - index}`">
                                <span class="case-status text-uppercase info-status mb-0 case-file" :class="[{'available': file?.link}]">
                                    {{ file?.label }}
                                </span>
                            </a>
                            <a href="javascript:void(0)" v-if="showDownloadCasesFileButton" @click="!filteredCaseFiles?.length ? null : $emit('showCaseFilesUploaded')" class="position-relative" style="z-index: 0;">
                                <span class="case-status text-uppercase info-status mb-0 case-file download-btn" :class="[{'available': filteredCaseFiles?.length }]">
                                    <i class="fas mr-1" :class="{'fa-arrow-down': filteredCaseFiles?.length, 'fa-circle-notch fa-spin': !filteredCaseFiles?.length }"></i> {{ !filteredCaseFiles?.length ? 'Retrieving' : 'Download' }}{{ !filteredCaseFiles?.length ? '...' : '' }}
                                </span>
                            </a>
                        </div>
                    </div>
                    
                    <p @click="$emit('click')" class="seller-item-details text-truncate mb-1 d-block" style="min-height: 0">{{ details }}</p>
                    <div @click="$emit('click')" class="row" style="width: 90%;">
                        <div class="col-md-6">
                            <div class="p-0 col-md-12 case-info mb-1">
                                <label class="text-dark font-weight-medium d-block m-0">
                                    <img src="/static/svg/Case_Defendants.svg" alt="icon" class="mr-1 position-relative" style="top: -2px">
                                    Total Defendants: <span class="ml-2 float-right font-weight-bold" style="color: #3B5998">{{ total_defendants }}</span>
                                </label>
                            </div>
                            <div class="p-0 col-md-12 case-info">
                                <label class="text-dark font-weight-medium d-block m-0">
                                    <img src="/static/svg/Case_Date.svg" alt="icon" class="mr-1 position-relative" style="top: -2px">
                                    Date Filed: <span class="ml-2 float-right font-weight-bold" style="color: #3B5998">{{ c_date_filed || "Not Set" }}</span>
                                </label>
                            </div>
                            <div class="p-0 col-md-12 case-info mb-1">
                                <label class="text-dark font-weight-medium d-block m-0">
                                    <img src="/static/svg/Case_Lawfirm.svg" alt="icon" class="mr-1 position-relative" style="top: -2px">
                                    Lawfirm: <span class="ml-2 float-right font-weight-bold text-right text-truncate" style="color: #3B5998; width: calc(100% - 130px)">{{ lawfirm_name || "Not Set" }}</span>
                                </label>
                            </div>
                            <div class="p-0 col-md-12 case-info">
                                <label class="text-dark font-weight-medium d-block m-0">
                                    <img src="/static/svg/Case_Judge.svg" alt="icon" class="mr-1 position-relative" style="top: -2px">
                                    Assigned Judge: <span class="ml-2 float-right font-weight-bold text-right text-truncate" style="color: #3B5998; width: calc(100% - 130px)">{{ judge || "Not Set" }}</span>
                                </label>
                            </div>
                            <div class="p-0 col-md-12 case-info mb-1" v-if="access.amounts">
                            <!-- <div class=" col-md-6 case-info mb-1" v-if="showNonDefaultedFigures"> -->
                                <label class="text-dark font-weight-medium d-block m-0">
                                    <img src="/static/svg/Case_Total.svg" alt="icon" class="mr-1 position-relative" style="top: -2px">
                                    Total Frozen: <span class="ml-2 float-right font-weight-bold" style="color: #3B5998">{{ total_frozen }}</span>
                                </label>
                            </div>
                            <div v-if="$featureAccess.isAllowed('cm-text-total-received')" class="p-0 col-md-12 case-info mb-1">
                                <label class="text-dark font-weight-medium d-block m-0">
                                    <img src="/static/svg/Case_Total.svg" alt="icon" class="mr-1 position-relative" style="top: -2px">
                                    Total Received: <span class="ml-2 float-right font-weight-bold" style="color: #3B5998">{{ total_received }}</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="p-0 col-md-12 case-info mb-1" v-if="access.amounts">
                            <!-- <div class=" col-md-6 case-info mb-1" v-if="showNonDefaultedFigures"> -->
                                <label class="text-dark font-weight-medium d-block m-0">
                                    <img src="/static/svg/Case_Total.svg" alt="icon" class="mr-1 position-relative" style="top: -2px">
                                    Total Agreed Settlement: <span class="ml-2 float-right font-weight-bold" style="color: #3B5998">{{ total_settled }}</span>
                                </label>
                            </div>
                            <div class="p-0 col-md-12 case-info mb-1" v-if="$featureAccess.isAllowed('cm-text-total-settlement-received')">
                                <label class="text-dark font-weight-medium d-block m-0">
                                    <img src="/static/svg/Case_Total.svg" alt="icon" class="mr-1 position-relative" style="top: -2px">
                                    Total Settlement Received: <span class="ml-2 float-right font-weight-bold" style="color: #3B5998">{{ total_settlement_received }}</span>
                                </label>
                            </div>
                            <div class="p-0 col-md-12 case-info mb-1" v-if="access_level() != 6 && $featureAccess.isAllowed('cm-text-total-default-received')">
                                <label class="text-dark font-weight-medium d-block m-0">
                                    <img src="/static/svg/Case_Total.svg" alt="icon" class="mr-1 position-relative" style="top: -2px">
                                    Total Default Received: <span class="ml-2 float-right font-weight-bold" style="color: #3B5998">{{ total_default_received }}</span>
                                </label>
                            </div>
                            <!-- v-if="access.amounts" -->
                            <div class="p-0 col-md-12 case-info mb-1">
                                <label class="text-dark font-weight-medium d-block m-0">
                                    <img src="/static/svg/Case_Total.svg" alt="icon" class="mr-1 position-relative" style="top: -2px">
                                    Total Expected Settlement: <span class="ml-2 float-right font-weight-bold" style="color: #3B5998">{{ total_expected_settlement }}</span>
                                </label>
                            </div>
                                <!--  v-if="(access.amounts && access.defaults) || access_level() == 7" -->
                                <div class="p-0 col-md-12 case-info mb-1 cm-text-predicted-default-amount"  v-if="access_level() != 6 && !isFeatureRestricted('cm-text-predicted-default-amount')">
                                <label class="text-dark font-weight-medium d-block m-0">
                                    <img src="/static/svg/Case_Total.svg" alt="icon" class="mr-1 position-relative" style="top: -2px">
                                    Total Predicted Default: <span class="ml-2 float-right font-weight-bold" style="color: #3B5998">{{ total_predicted_default }}</span>
                                </label>
                            </div>
                            <div class="p-0 col-md-12 case-info mb-1" v-if="access_level() != 6 && $featureAccess.isAllowed('cm-text-total-ctda-default-received')">
                                <label class="text-dark font-weight-medium d-block m-0">
                                    <img src="/static/svg/Case_Total.svg" alt="icon" class="mr-1 position-relative" style="top: -2px">
                                    Total CTDA Default Received: <span class="ml-2 float-right font-weight-bold" style="color: #3B5998">{{ total_ctda_default_received }}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="case-list-btns">
                        <slot name="case-btns"></slot>
                    </div>
                    <div class="case-platform-con" :key="platform_img_key">    
                        <!-- img
                            v-for="(item, index) in reducedPlatformImgs"
                            :key="index" 
                            class="platform-icon fadeIn slower"
                            :src="item.src"
                            alt="Platform Image"
                        > -->
                        <span v-tooltip.right="{content: truncatedCasePlatforms }" class="platform-icon d-inline-block font-weight-bold text-secondary" style="font-size: 12px; width: 80px;"> <!--  style="background: url('/static/img/platforms/Default.jpg') center center / cover; border:1px solid #ddd; vertical-align:middle; text-align:center; padding:3.5px 2px 0; width: auto; min-width:25px; font-size: 12px;" -->
                            <b class="text-uppercase d-inline-block" style="border-bottom: 3px solid #6c757d">Platforms</b>
                            <!-- <span v-if="reducedPlatformImgs.length" class="position-absolute d-flex align-items-center justify-content-center" style="top: 0; left: 0; right: 0; bottom: 0; width: 100%; height: 100%;">
                                < !-- +{{ platform_imgs.platform_count }} -- >
                            </span> -->
                        </span>
                    </div>
                </div>
            </template>
            <template v-else-if="type == 'defendant'">
                <div class="img-container">
                    <ar-checkbox v-if="has_checkbox" :id="rid" :value="rid" name="listings"  @click="selected_function(index)"></ar-checkbox>
                    <slot></slot>
                    <div v-lazy-container="{ selector: 'img' }">
                        <img :data-src="preview()" data-error="/static/svg/IMG_BROKEN.svg" :alt="details">
                    </div>
                </div>
                <div class="seller-info">
                    <img v-if="campaign" class="list-campaign-img" :title="campaign" :src="campaign_img" :alt="campaign">
                    <slot name="viewUploads" style="z-index: 1000"></slot>
                    <a href="javascript:void(0);" class="d-block" id="modal-opener" :data-toggle="!copy_rid ? 'modal' : ''" :data-target="target" @click="!copy_rid ? $emit('click') : ''">
                        <span style="font-size:20px;letter-spacing: 0.19px;color: #000000DE;font-weight: 400;">
                            <span :class="{'text-danger': !seller_name}">{{ seller_name || "Seller Not Found" }}</span>
                            <span v-if="if_reassigned" class="status-badge ml-2" style="border-radius: 37%"><i class="fas fa-share"></i></span>
                            <span v-if="if_commission" class="status-badge green ml-1" style="border-radius: 37%" title="Listing has commission"><i class="fas fa-dollar-sign"></i></span>
                        </span>
                        <span class="id-status mt-1 text-truncate" :class="{'mb-3': !progress}">
                            <span class="d-inline-block position-relative align-middle mr-1 text-uppercase font-weight-bold">Doe Number {{doe_no}}</span>
                            | <span class="d-inline-block position-relative align-middle ml-1">
                                <ar-button class="a-btn-text" style="font-size: 12px; font-weight: 400; padding: 1px 5px 1px; left:-4px; float:left;" title="Copy listing id" @mouseover="isCopying(true)" @mouseleave="isCopying(false)" @click="copytoClipboard(rid)">{{ rid }}</ar-button>
                            </span>
                            <span class="d-inline-block position-relative" style="left:-3px">
                                <span title="Research status">
                                    | <img style="width: 10px; position:relative; top:-1px; margin:0 2px 0 4px" :src="parent.getQFlagIcon(parent.getQFlagId(status.toString().toLowerCase()))" alt="qflag">
                                    {{ status }}
                                </span>
                                <span title="Seller Flag" class="ml-2" v-if="$route.path.includes('/lawfirm') && $route.params.case_id != '0'">
                                    | <img style="width: 10px; position:relative; top:-1px; margin:0 2px 0 6px" src="/static/svg/Case_flag.svg" alt="sellerflag">
                                    {{ typeof case_flag == 'undefined' || case_flag == null ? 'No Agreement' : case_flag.toLowerCase().includes('default') && !access.defaults ? "No Agreement" : case_flag  }}
                                    <!-- {{ typeof case_flag == 'undefined' || case_flag == null ? 'No Agreement' : case_flag.toLowerCase().includes('default') && isFeatureRestricted("cm-text-non-defaulted-figures") ? "No Agreement" : case_flag  }} -->
                                </span>
                                <span title="Researcher" class="text-danger ml-2" v-if="!$route.path.includes('lawfirm')">
                                    | <img style="width: 10px; position:relative; top:-1px; margin:0 2px 0 4px" src="/static/svg/Users_Researchers.svg" alt="researchers"/>
                                    {{owner}}
                                </span>
                                <span v-if="group_name" class="ml-2" v-tooltip="group_description">
                                    | <img style="width: 10px; position:relative; top:-1px; margin:0 2px 0 4px" src="/static/svg/defendants/Group_Name.svg" alt="Group Name">
                                    {{ group_name }}
                                </span>
                            </span>
                        </span>
                        <p class="mt-3 seller-item-details line-clamp">{{ details }}</p>
                    </a>
                    <!-- <div v-if="group_name" class="position-absolute d-inline-block animated fadeIn" style="right: 13px; top: 14px;" v-tooltip="group_description">
                        <img 
                            class="group-name d-inline-block align-middle mr-2"
                            style="height: 24px; width: 24px;"
                            src="/static/svg/defendants/Group_Name.svg"
                            alt="Group Name"
                        >
                        <span 
                            class="d-inline-block align-middle text-primary text-truncate text-uppercase" 
                            style="letter-spacing: 0.28px; font-weight: 600; max-width: 200px"
                        >
                            {{ group_name }}
                        </span>
                    </div> -->
                    <a :href="product_url" :target="product_url != 'javascript:void(0);' || product_url != '' ? '_blank' : '_self'">
                        <img 
                            class="platform-icon"
                            style="bottom: initial; top: 52px"
                            src="/static/svg/Icon_Productpage.svg" 
                            title="Go to product page" 
                            alt="Product page image"
                        >
                    </a>
                    <a :href="seller_url" :target="seller_url != 'javascript:void(0);' || seller_url != '' ? '_blank' : '_self'">
                        <img 
                            class="platform-icon"
                            style="bottom: initial; top: 88px" 
                            :src="platform_img" 
                            title="Go to store page" 
                            alt="Platform Image"
                        >
                    </a>
                </div>
                <div class="accounts-con" :class="{'no-account': accounts.length == 0, 'no-defaults': !access.defaults, 'no-amounts': !access.amounts}">
                    <div class="row p-0">
                        <div class="col-md-6 mb-1" v-for="(account, x) in accounts" :key="x">
                            <div class="pr-2 pl-4" :class="{'border-left': (x + 1) % 2 == 0 }">
                                <!-- v-if="!access.readonly || [7].includes(access_level())" -->
                                <a 
                                    href="javascript:void(0)"
                                    title="Edit this account"
                                    style="text-decoration:underline !important"
                                    class="d-inline-block text-uppercase mt-2 mb-2 cm-button-edit-details"
                                    data-toggle="modal"
                                    :data-target="edit_seller_modal"
                                    @click="edit_seller_account(index,x)"
                                    v-restrict ="'cm-button-edit-details'"
                                >Edit Details</a>
                                <table class="table table-borderless" :class="{'mt-3': !access.amountsDefaults}">
                                    <tbody>
                                        <tr v-for="(info, index) in account" :key="index">
                                            <td class="header-label text-truncate" v-if="info.title"><img v-if="info.image" :src="info.image" alt="icon" class="mr-2">{{ info.title }}<span v-if="info.value">:</span></td>
                                            <td v-if="info.value" class="text-truncate"><b :class="{'main-seller-account-grid': info.id == seller_account_id }">{{ info.value }}</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else-if="type == 'accounting'">
                <div class="pl-2 pb-2 pr-2">
                    <div class="d-flex justify-content-between align-items-center pl-2 pr-2">
                        <h2 style="font-weight: 500; font-size: 20px; width: 410px;" class="text-truncate">{{ accounting_data.case_number }}</h2>
                        <div class="position-relative" style="width: 103px; height: 18px; z-index: 5">
                            <div class="non-hover-options-con animated fadeInRight faster" v-if="more_details && has_show_hovered_options">
                                <slot name="hovered-options"></slot>
                            </div>
                            <a href="javascript:void(0)" class="position-absolute details-toggle" style="top: 0; left: 0; right: 0; bottom: 0; width: 100%; color:#480048; font-weight: 700;" @click="more_details = !more_details">{{ more_details ? 'Less' : 'More' }} Details <i class="fas fa-chevron-circle-down ml-1" :class="{'expanded': more_details}"></i></a>
                        </div>
                    </div>
                    <div @mouseover="has_hovered_options && !more_details ? hovered = true : null" @mouseleave="has_hovered_options && !more_details ? hovered = false : null">
                        <hr style="border-color: #7070706E !important" class="mt-2"/>
                        <div class="row p-0" v-for="(part, part_index) in accounting_data.report" :key="part_index">
                            <div :class="`col-md-${['total_amounts', 'another_total_amounts', 'basic_details', 'filing_dates'].includes(key) ? 6 : 12}`" v-for="(report, key, accounting_index) in part" :key="accounting_index">
                                <template v-if="key == 'distributed_amounts'">
                                    <div class="d-flex justify-content-between align-items-center mb-1">
                                        <h3 class="text-uppercase ml-2" style="color: #300030; letter-spacing: 0.66px; font-weight: 500; font-size: 20px;">Received Funds</h3>
                                    </div>
                                    <table class="table table-borderless m-0">
                                        <thead>
                                            <th></th>
                                            <th>Total</th>
                                            <th>Other Costs</th>
                                            <th>Distributed</th>
                                            <th>For Distribution</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(info, index) in report" :style="`${index != report.length - 1 ? 'border-bottom: 1px dashed #7070706E' : ''}`" :key="index">
                                                <td class="text-truncate"><b>{{ info.label }}</b></td>
                                                <td class="text-truncate"><b>{{ info.total }}</b></td>
                                                <td class="text-truncate"><b>{{ info.other_cost }}</b></td>
                                                <td class="text-truncate"><b>{{ info.distributed }}</b></td>
                                                <td class="text-truncate"><b>{{ info.for_distribution }}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </template>
                                <template v-else-if="key != 'distribution_rates'">
                                    <table class="table table-borderless m-0">
                                        <tbody>
                                            <template v-if="key == 'entity_percentage'">
                                                <tr :style="`${index % 2 != 0 && index != report.length - 1 ? 'border-bottom: 1px solid #7070706E' : ''}`" v-for="(info, index) in report" :key="index">
                                                    <td class="header-label text-truncate"><img :src="info.img" alt="icon" class="mr-2">{{ info.title }}</td>
                                                    <td class="text-right text-truncate"><b>{{ info.value }}</b></td>
                                                </tr>
                                            </template>
                                            <template v-else>
                                                <tr v-for="(info, index) in report" :key="index">
                                                    <td class="header-label text-truncate"><img :src="info.img" alt="icon" class="mr-2">{{ info.title }}</td>
                                                    <td class="text-right"><b class="text-truncate d-block" :class="{'judge-name': info.title == 'Judge'}">{{ info.value }}</b></td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </template>
                                <template v-else>
                                    <div class="d-flex justify-content-between align-items-center mb-3">
                                        <h3 class="text-uppercase ml-2" style="color: #300030; letter-spacing: 0.66px; font-weight: 500; font-size: 20px;">Distribution Rates</h3>
                                        <ar-button class="accent" @click="$emit('distribute-funds-click')">Distribute Funds</ar-button>
                                    </div>
                                    <table class="table table-borderless m-0">
                                        <thead>
                                            <th class="header-label">Entity Name</th>
                                            <th>Source of Income</th>
                                            <th>Other Deductions</th>
                                            <th>Distributed</th>
                                            <th>For Distribution</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(info, index) in report" :style="`${index != report.length - 1 ? 'border-bottom: 1px dashed #7070706E' : ''}`" :key="index">
                                                <td class="text-truncate"><b>{{ info.entity_name }}</b></td>
                                                <td class="text-truncate">
                                                    <div
                                                        v-for="source in info.funds"
                                                        :key="source.value"
                                                        :class="[source.value, 'rate-badge']"
                                                    >
                                                        {{ source.name }}
                                                        {{ source.value === 'default' ?
                                                                `(${info.percentage_default}%)` :
                                                                `(${info.percentage_settlement}%)`
                                                        }}
                                                    </div>
                                                </td>
                                                <td class="text-truncate">
                                                    <div
                                                        v-for="deduction in info.deductions"
                                                        :key="deduction"
                                                        class="rate-badge deduction"
                                                    >
                                                        {{ deduction }}
                                                    </div>
                                                </td>
                                                <td class="text-truncate"><b>{{ info.distributed }}</b></td>
                                                <td class="text-truncate"><b>{{ info.for_distribution }}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </template>
                            </div>
                            <hr v-if="part_index != accounting_data.report.length - 1" class="w-100" style="border-color: #7070706E !important; border-style: dashed"/>
                        </div>
                    </div>
                </div>
                <div @mouseover="has_hovered_options && !more_details ? hovered = true : null" @mouseleave="has_hovered_options && !more_details ? hovered = false : null" class="hovered-options" :class="{'hovered': hovered}">
                    <div class="hovered-option-con">
                        <slot name="hovered-options"></slot>
                    </div>
                </div>
            </template>
            <template v-else-if="type=='auto-request'">
                <div
                    @click="$emit('click')"
                    data-toggle="modal"
                    :data-target="target"
                    class="request-list-item-container"
                    @mouseover="has_hovered_options ? hovered = true: null"
                    @mouseleave="has_hovered_options ? hovered = false: null"
                >
                    <div v-if="hovered" style="display: flex; gap: 5px;align-items: center;justify-content: flex-end;position: absolute; top: 0; right: 0; width: auto;margin-top: 12px;margin-right: 16px;">
                        <ar-button
                            v-if="status !== 'processing' && status !== 'pending'"
                            @click.stop="$emit('rescan')"
                            icon="fas fa-retweet auto-request"
                            a_class="auto-request-change-status animated fadeIn faster btn-warning"
                            style="font-size: 12px;color: #FFFFFF;font-weight: 500;padding:4px 15px 4px 30px;z-index: 1;"
                        >Rescan</ar-button>
                        <ar-button
                            v-if="status=='success'"
                            @click.stop="$emit('change-status')"
                            icon="fas fa-desktop auto-request"
                            a_class="auto-request-change-status animated fadeIn faster"
                            style="font-size: 12px;color: #FFFFFF;font-weight: 500;padding:4px 15px 4px 30px;z-index: 1;"
                        >Change Status</ar-button>
                        <ar-button
                            v-if="status !== 'processing' && status !== 'pending'"
                            @click.stop="$emit('delete-request')"
                            icon="fas fa-trash auto-request"
                            a_class="auto-request-delete-request animated fadeIn faster"
                            style="font-size: 12px;color: #FFFFFF;font-weight: 500;padding:4px 15px 4px 30px;z-index: 1;"
                        >Delete Request</ar-button>
                    </div>
                    <div v-if="status=='success'" style="display: flex; gap: 10px; margin-bottom: 10px;">
                        <div>
                            <div v-lazy-container="{ selector: 'img' }" style="overflow: hidden;position: relative;width: 100px;height: 80px;border: 1px solid #2D599E; border-radius: 8px;">
                                <img style="position: absolute;width: 100px;height: 80px; transform: translate(-50%, -50%) scale(1); -webkit-transform: translate(-50%, -50%) scale(1); top: 50%; left: 50%" :data-src="preview()" alt="" onerror="this.src='/static/svg/Listing Default Photo.svg'">
                            </div>
                        </div>
                        <div style="display:flex;flex-direction: column; gap: 5px; align-items: flex-start; justify-content: flex-start; width: 100%;">
                            <p class="m-0" style="font-size: 16px;font-weight: bold;color:#203154;">{{seller_name}}</p>
                            <p class="m-0" style="color: #383A3D; font-size: 14px;">{{listing_title}}</p>
                        </div>
                    </div>
                    <span class="id-status" :class="{'auto-request': type=='auto-request'}">
                        <span class="d-inline-block position-relative align-middle">
                            <ar-button
                                a_class="inverted"
                                style="font-size: 10px; font-weight: 500; padding: 1px 5px 1px; border-radius: 4px;"
                                title="Copy listing id"
                                @mouseover="isCopying(true)"
                                @mouseleave="isCopying(false)"
                                @click="copytoClipboard(rid)"
                            >{{ rid }}</ar-button>
                        </span>
                        <span class="d-inline-block position-relative align-middle">
                            <div
                                class="auto-request-status"
                                :class="{'success': status == 'success', 'pending': status =='pending','processing': status=='processing', 'failed': status=='failed', 'completed-with-issues': status=='completed_with_issues'}"
                            >{{ status == 'completed_with_issues' ? 'completed with issues' : status}}</div>
                        </span>
                        <span class="d-inline-block position-relative align-middle">
                            <div
                                class="auto-request-status"
                            >
                                <img :src="campaign_img" alt="">
                                <p class="m-0">{{campaign}}</p>
                            </div>
                        </span>
                        <span class="d-inline-block position-relative align-middle">
                            <div
                                class="auto-request-status"
                            >
                                <img :src="platform_img" alt="">
                                <p class="m-0">{{platform}}</p>
                            </div>
                        </span>
                        <span class="d-inline-block position-relative align-middle">
                            <div
                                class="auto-request-status"
                            >date requested - <b style="color:#3E88DA; margin-left: 3px;">{{ auto_requested_date }}</b></div>
                        </span>
                        <span class="d-inline-block position-relative align-middle">
                            <div
                                class="auto-request-status"
                            >date completed - <b style="color:#3E88DA; margin-left: 3px;">{{ auto_completed_date }}</b></div>
                        </span>
                    </span>
                    <div class="request-list-info">
                        <div class="request-list-item">
                            <div
                                class="auto-request-status"
                            >
                                <img src="/static/svg/ListingInfo_ItemType.svg" alt="product url">
                                product url
                            </div>
                            <a @click.stop="" :href="product_url" target="_blank" class="request-list-desc m-0 text-break" >{{product_url}}</a>
                        </div>
                        <div class="request-list-item">
                            <div
                                class="auto-request-status"
                            >
                                <img src="/static/svg/ListingInfo_SellerURL.svg" alt="product url">
                                store url
                            </div>
                            <a @click.stop="" :href="seller_url" target="_blank" class="request-list-desc m-0 text-break">{{seller_url}}</a>
                        </div>
                        <template v-if="last_errors !== null && status !== 'success'">    
                            <div
                                class="request-list-error"
                            >
                                <div 
                                    class="request-list-error-item"
                                    v-for="(err, index) in last_errors"
                                    :key="index"
                                >
                                <ul>
                                    <li> {{ err }} </li>
                                </ul>
                                    <!-- <span /> {{err}} -->
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="img-container">
                    <template v-if="$route.path.includes('lawfirm')">
                        <ar-checkbox v-if="has_checkbox" :id="rid" :value="rid" name="listings"  @click="selected_function(index)"></ar-checkbox>
                    </template>
                    <template v-else>
                        <ar-checkbox v-if="has_checkbox" :id="rid" :value="rid" name="listings"  @change="$emit('change',rid)"></ar-checkbox>
                    </template>
                    <slot></slot>
                    <div v-lazy-container="{ selector: 'img' }">
                        <img :data-src="preview()" data-error="/static/svg/IMG_BROKEN.svg" :alt="details">
                    </div>
                </div>
                <div class="seller-info">
                    <img v-if="campaign" class="list-campaign-img" :title="campaign" :src="campaign_img" :alt="campaign">
                    <a class="d-block" id="modal-opener" :data-toggle="!copy_rid ? 'modal' : ''" :data-target="target" @click="!copy_rid ? $emit('click') : ''">
                        <span v-if="doe_no != '' && doe_no != null && status == 'Filed'" class="doe-no">Doe No. {{doe_no}} &nbsp;&nbsp;&nbsp;</span>
                        <span style="font-size:20px;letter-spacing: 0.19px;color: #000000DE;font-weight: 400;">
                            <span :class="{'text-danger': !seller_name}" class="grid-seller-name text-truncate">{{ seller_name || "Seller Not Found" }}</span>
                            <span v-if="if_reassigned" class="status-badge ml-2"  style="border-radius: 37%"><i class="fas fa-share"></i></span>
                            <span v-if="if_commission" class="status-badge green ml-1" style="border-radius: 4px" title="Listing has commission"><i class="fas fa-dollar-sign"></i></span>
                            <span v-if="claim_type" :title="claim_type" class="status-badge claimtype-icon ml-1">
                                <img :src="icon" v-for="(icon, icindex) in getClaimtypeIcon(claim_type)" :key="icindex" alt="claim-type-icon">
                            </span>
                        </span>
                        <span class="id-status" :class="{'mb-3': !progress, 'new-design': $route.name.includes('lawfirm')}">
                            <template v-if="!$route.name.includes('lawfirm')">
                                <span class="d-inline-block position-relative align-middle">
                                    <ar-button style="font-size: 12px; font-weight: 400; padding: 1px 5px 1px; border-radius: 4px;" title="Copy listing id" @mouseover="isCopying(true)" @mouseleave="isCopying(false)" @click="copytoClipboard(rid)">{{ rid }}</ar-button>
                                </span>
                                <span title="Research status" class="misc-details">
                                    <img style="width: 10px; position:relative; top:-1px; margin-right: 4px;" :src="parent.getQFlagIcon(parent.getQFlagId(status.toString().toLowerCase()), true)" alt="qflag">
                                    {{ status }}
                                </span>
                            </template>
                            <!-- Filed: qflag == 34 -->
                            <span v-if="parent.getQFlagId(status.toString().toLowerCase()) == 34" class="misc-details">
                                {{ case_number }}
                            </span>
                            <template v-if="has_researcher">
                                <!-- <template v-if="![3].includes(access_level()) && !$route.path.includes('lawfirm')"> -->
                                <template v-if="!$route.path.includes('lawfirm')">
                                    <span title="Researcher" class="misc-details researcher v-chip-researcher" :class="{'list-status-truncate text-truncate': status == 'Filed'}" v-restrict="'v-chip-researcher'">
                                        <img style="width: 10px; position:relative; top:-1px; margin-right: 4px" src="/static/svg/Users_Researchers.svg" alt="researchers"/>
                                           {{owner}}
                                    </span>
                                    <span 
                                        v-if="(qualifier ? qualifier.trim() : '') != ''"
                                        v-restrict="'v-chip-qa'"
                                        title="QA"
                                        class="misc-details qa v-chip-qa"
                                        :class="{'list-status-truncate text-truncate': status == 'Filed'}">
                                        <img style="width: 10px; position:relative; top:-1px; margin-right: 4px" src="/static/svg/Users_QA.svg" alt="qa"/>
                                        {{(qualifier ? qualifier.trim() : '')}}
                                    </span>
                                    <span title="Date Added" class="misc-details v-chip-date-added" v-restrict="'v-chip-date-added'">
                                        <img style="width: 10px; position:relative; top:-1px; margin-right: 4px" src="/static/svg/counterfeit/Date_Added.svg" alt="date-added"/>
                                        {{date}}
                                    </span>
                                    <!-- 
                                        Deliverable: Hide all For Rework/Reworked elements across all GUI
                                        Deliverable Number: 454
                                     -->
                                    <!-- <span title="Last 'For Rework' Date" class="misc-details" v-if="last_rework != 'Invalid date' && ((status == 'Recheck' || status == 'For Rework' && this.$route.name != 'search') || ((this.$route.params.status == 'All' || this.$route.params.status == 'For Rework') && this.$route.name == 'search'))">
                                        <img style="width: 10px; position:relative; top:-1px; margin-right: 4px" src="/static/svg/ListingInfo_History.svg" alt="last-rework"/>
                                        {{last_rework}}
                                    </span> -->
                                    <span v-if="payment_type" title="Payment Type" class="misc-details v-chip-payment-type"  v-restrict="'v-chip-payment-type'">
                                        <img style="width: 10px; position:relative; top:-1px; margin-right: 4px" src="/static/svg/defendants/Details_Account.svg" alt="payment_type"/>
                                        {{ payment_type }}
                                    </span>
                                    <!-- <span title="Claim Type" class="misc-details" v-if="claim_type">
                                        <img style="width: 10px; position:relative; top:-1px; margin-right: 4px" src="/static/svg/briefcase.svg" alt="claim_type"/>
                                        {{claim_type}}
                                    </span> -->
                                </template>
                                <span title="Seller Flag" class="ml-2" :class="{'list-status-truncate text-truncate': status == 'Filed'}" v-if="$route.path.includes('/lawfirm') && $route.params.case_id != '0'">
                                    <img style="width: 10px; position:relative; top:-1px; margin-right: 4px" :src="parent.getQFlagIcon(parent.getQFlagId('preverified'))" alt="sellerflag">
                                    {{ typeof case_flag == 'undefined' || case_flag == null ? 'No Agreement' : case_flag.toLowerCase().includes('default') && !access.defaults ? "No Agreement" : case_flag  }}
                                    <!-- {{ typeof case_flag == 'undefined' || case_flag == null ? 'No Agreement' : case_flag.toLowerCase().includes('default') && isFeatureRestricted("cm-text-non-defaulted-figures") ? "No Agreement" : case_flag  }} -->
                                </span>
                                <div class="d-inline-block position-relative align-middle misc-details casenum" v-if="$route.path.includes('/verification') && status == 'Filed' && case_number">
                                    <span class="d-inline-block align-middle">Case #</span> <ar-button class="a-btn-text text-truncate list-status-truncate" style="font-size: 12px; font-weight: 400; font-weight:inherit; font-size:inherit; padding: 0 5px; border-radius: 0 4px 4px 0; max-width: 200px;" title="Copy case number" @mouseover="isCopying(true)" @mouseleave="isCopying(false)" @click="copytoClipboard(case_number, 'case number')">{{ case_number }}</ar-button>
                                </div>
                            </template>
                        </span>
                    
                        <!-- <ar-progress-bar
                            v-if="progress"
                            :size="'sm'"
                            :percent="progress"
                        /> -->
                        <p class="mt-3 seller-item-details line-clamp" :class="{'lawfirm': $route.name.includes('lawfirm')}">{{ details }}</p>
                        <!-- new suggested design -->
                        <div class="right-side-position" v-if="$route.name.includes('lawfirm')" :class="{'hasGroupName': group_name !== '' && group_name !== null}">
                            <span class="d-inline-block position-relative align-middle">
                                <ar-button style="font-size: 12px; font-weight: 400; padding: 1px 5px 1px; border-radius: 4px;" title="Copy listing id" @mouseover="isCopying(true)" @mouseleave="isCopying(false)" @click="copytoClipboard(rid)">{{ rid }}</ar-button>
                            </span>
                            <span title="Research status" class="misc-details" 
                                :class="{
                                    'status-others': status !== 'Monitoring' && status !== 'Rejected' && status !== 'Accepted',
                                    'status-monitoring': status == 'Monitoring',
                                    'status-rejected': status == 'Rejected',
                                    'status-accepted': status == 'Accepted',
                                }"
                            >
                                <img style="width: 10px; position:relative; top:-1px; margin-right: 4px;" :src="parent.getQFlagIcon(parent.getQFlagId(status.toString().toLowerCase()), true)" alt="qflag">
                                {{ status }}
                            </span>
                        </div>
                    </a>
                    <!-- here -->
                    <template v-if="$route.name.includes('lawfirm')">
                        <div class="new-goto">
                            <a :href="product_url" :target="product_url != 'javascript:void(0);' || product_url != '' ? '_blank' : '_self'">
                                <span class="misc-details special" style="display: flex;align-items: center;justify-content: center; gap: 4px;text-transform: uppercase;">
                                    <img title="Go to Store Page" :src="product_img" alt="product" style="width: 12px;height: 12px;border: 1px solid #3E88DA24;" onerror="this.src='/static/svg/Campaign Default Photo.svg'">
                                    Go to Product Page
                                </span>
                            </a>
                            <a :href="seller_url" :target="seller_url != 'javascript:void(0);' || seller_url != '' ? '_blank' : '_self'">
                                <span class="misc-details simple" style="display: flex;align-items: center;justify-content: center; gap: 4px;text-transform: uppercase;">
                                    <img title="Go to Store Page" :src="platform_img" alt="store" style="width: 12px;height: 12px;">
                                    Go to Store Page
                                </span>
                            </a>
                            <!-- href="javascript:void(0);" -->
                            <a
                                class="lawfirm-btn misc-details export-pdf"
                                :title="mergedEvidence.type"
                                @click="getUploadedEvidences(rid)"
                                :key="fullmerge_key"
                            >
                                <!-- :href="previewFullMerge(mergedEvidence.image, true)" target="_blank" :title="mergedEvidence.type" -->
                                <!-- @click="editCaseDetails(listing.id)" -->
                                <img style="width: 10px; position:relative; top:-1px; margin:0 2px 0 4px" src="/static/svg/case-upload/PDF.svg" alt="PDF Icon" title="Export as PDF">
                                <!-- Full Merge -->
                                <!-- {{ mergedEvidence == 'missing' || mergedEvidence?.image?.includes('Missing') ? 'Full Merge | Missing' : 'Full Merge' }} -->
                                {{ mergedEvidence == 'missing' || mergedEvidence?.image?.includes('Missing') ? 'Evidence | Missing' : 'Evidence' }}
                            </a>
                        </div>
                    </template>
                    <div v-if="group_name" class="position-absolute d-inline-block animated fadeIn" style="right: 13px; top: 14px;">
                        <img 
                            class="group-name d-inline-block align-middle mr-2"
                            style="height: 24px; width: 24px;"
                            src="/static/svg/defendants/Group_Name.svg"
                            alt="Group Name"
                        >
                        <span 
                            class="d-inline-block align-middle text-primary text-truncate text-uppercase" 
                            style="letter-spacing: 0.28px; font-weight: 600; max-width: 200px" 
                            :title="group_name"
                        >
                            {{ group_name }}
                        </span>
                    </div>
                    <!-- <a :href="product_url" :target="product_url != 'javascript:void(0);' || product_url != '' ? '_blank' : '_self'">
                        <img class="platform-icon" :src="platform_img" alt="Platform Image">
                    </a> -->
                    <a v-if="has_platform && !$route.name.includes('lawfirm')" :href="seller_url" :target="seller_url != 'javascript:void(0);' || seller_url != '' ? '_blank' : '_self'">
                        <img 
                            class="platform-icon"
                            :style="`${$route.params.status == 'Cases' && status == 'Filed' ? 'bottom: initial; top: 52px;' : ''}`" 
                            :src="platform_img" 
                            title="Go to store page" 
                            alt="Platform Image"
                        >
                    </a>
                    <a class="defendant-details-toggle" v-if="($route.params.status == 'Cases' && status == 'Filed') && seller_accounts.length > 0" @click="more_details = !more_details">{{ more_details ? 'Show less' : 'Show more' }} <i class="fas fa-chevron-circle-down" :class="{'expanded':more_details}"></i></a>
                </div>
            </template>
        </div>
        <div v-if="!['defendant', 'case', 'accounting'].includes(type)" class="defendant-details-con" :class="{'expanded': more_details}">
            <div class="details-con">
                <div class="m-2 p-2 p-0 row" :class="{'main-seller-account': seller_account_id == account.seller_account_id}" v-for="(account,x) in seller_accounts" :key="x">
                    <div class="col-md-4">
                        <div class="a-modal-seller-info-item" style="background:#ffffff00 0% 0% no-repeat padding-box; padding: 22px 0 0 67px">
                            <img src="/static/svg/SellerInfo_ContactPerson.svg" alt="icon">
                            <h5 >{{account.name_email}}
                                <span>{{account.payment_name}}</span>
                            </h5>
                        </div>
                    </div>
                    <div class="col-md-4">
                        Frozen Amount: <a style="color: #007bff;" data-toggle="modal" :data-target="edit_seller_modal" @click="edit_seller_account(index,x)">{{money(account.amount_frozen)}}</a> <br>
                        Released Amount: <a style="color: #007bff;" data-toggle="modal" :data-target="edit_seller_modal" @click="edit_seller_account(index,x)">{{money(account.amount_released)}}</a> <br>
                    </div>
                    <div class="col-md-4">
                        Agreed Settlement Amount : <a style="color: #007bff;" data-toggle="modal" :data-target="edit_seller_modal" @click="edit_seller_account(index,x)">{{money(account.amount_settled)}}</a> <br>
                        Account Number: <a style="color: #007bff;"  data-toggle="modal" :data-target="edit_seller_modal" @click="edit_seller_account(index,x)">{{account.number}}</a> <br>
                        <!-- Seller Flag: <a style="color: #007bff;" data-toggle="modal" :data-target="edit_seller_modal" @click="edit_seller_account(index,x)">{{typeof case_flag == 'undefined' || case_flag == null ? 'Not Set' : case_flag }}</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import progressbar from './Progressbar'
import checkbox from './Checkboxes'
import button from './Button'
import {mapActions, mapGetters, mapState} from 'vuex'
import moment from 'moment'
import axios from '../../axiosMime'
import { isFeatureRestricted } from '../../utils/common';
import {getRandomKey} from '@/utils/common'

export default {
    name: "ar-list",
    components: {
        // 'ar-progress-bar': progressbar,
        'ar-checkbox': checkbox,
        'ar-button': button
    },
    props: {
        checked: {
            type: [Boolean, Number]
        },
        edit_seller_modal: {
            value : String,
            default: ''
        },
        edit_seller_account: {
            value : Function,
            default : function(){}
        },
        seller_accounts: {
            value : Array,
            default : ()=>[]
        },
        index: {
            value : String,
            default : ''
        },
        is_small: {
            type: Boolean,
            default: false
        },
        a_class: {
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        action: {
            type: String,
            default: 'edit'
        },
        case_flag: {
            type: String,
            default: 'No Agreement'
        },
        product_img: {
            type: String,
            default: ''
        },
        seller_name: {
            type: String,
            default: ''
        },
        doe_no: {
            type: String,
            default: ''
        },
        if_reassigned: {
            type: Boolean,
            default: false
        },
        if_commission: {
            default: false
        },
        rid: {
            default: '' 
        },
        progress: {
            type: Number,
            default: 0
        },
        status: {
            default: ''
        },
        details: {
            type: String,
            default: ''
        },
        case_name: {
            type: String,
            default: ''
        },
        last_rework: {
            type: String,
            default: ''
        },
        claim_type: {
            type: String,
            default: ''
        },
        payment_type: {
            type: String,
            default: ''
        },
        platform_img: {
            type: String,
            default: '/static/svg/Campaign Default Photo.svg'
        },
        platform_imgs: {
            type: Object,
            default: () => ({ platforms: ['/static/svg/Campaign Default Photo.svg'] })
        },
        platform: {
            type: String,
            default: ''
        },
        campaign_img: {
            type: String,
            default: '/static/svg/Campaign Default Photo.svg'
        },
        campaign: {
            type: String,
            default: ''
        },
        has_checkbox: {
            type: Boolean,
            default: true
        },
        name: {
            type: String,
            default: ''
        },
        invalid: {
            type: Boolean,
            default: false
        },
        user_action: {
            type: String,
            default: ''
        },
        subject: {
            type: String,
            default: ''
        },
        object: {
            type: String,
            default: ''
        },
        date: {
            type: String,
            default: ''
        },
        target: {
            type: String,
            default: ''
        },
        product_url: {
            type: String,
            default: 'javascript:void(0);'
        },
        selected_function: {
            value: Function,
            default: function(){}
        },
        case_imgs: {
            type: Array,
            default: () => ['']
        },
        total_frozen: {
            type: String,
            default: '0.00'
        },
        total_settled: {
            type: String,
            default: '0.00'
        },
        total_received: {
            type: String,
            default: '0.00'
        },
        total_released: {
            type: String,
            default: '0.00'
        },
        total_expected_settlement: {
            type: String,
            default: '0.00'
        },
        total_predicted_default: {
            type: String,
            default: '0.00'
        },
        total_defendants: {
            type: String,
            default: '0'
        },
        total_settlement_received: {
            type: String,
            default: '0.00'
        },
        total_default_received: {
            type: String,
            default: '0.00'
        },
        total_ctda_default_received: {
            type: String,
            default: '0.00'
        },
        seller_url: {
            type: String,
            default: ''
        },
        status_icon: {
            type: String,
            default: ''
        },
        field: {
            type: String,
            default: ''
        },
        owner:{
            default:""
        },
        qualifier:{
            default: ""
        },
        seller_account_id: {
            default: ""
        },
        case_number: {
            default: ""
        },
        group_name: {
            type: String,
            default: null
        },
        group_description: {
            type: String,
            default: null
        },
        accounting_data: {
            type: Object,
            default: () => ({})
        },
        has_hovered_options: {
            type: Boolean,
            default: false
        },
        has_show_hovered_options: {
            type: Boolean,
            default: false
        },
        has_platform: {
            type: Boolean,
            default: true
        },
        has_researcher: {
            type: Boolean,
            default: true
        },
        case_img_key: {
            default: 0
        },
        platform_img_key: {
            default: 0
        },
        lawfirm_name: {
            type: String,
            default: ""
        },
        isHtml: {
            type: Boolean,
            default: true
        },
        judge: {
            type: String,
            default: ""
        },
        date_filed: {
            type: String,
            default: ""
        },
        case_status_options: {
            type: Array,
            default: () => ([])
        },
        auto_requested_date: {
            type: String,
            default: ''
        },
        auto_completed_date: {
            type: String,
            default: ''
        },
        // for auto-capture listings
        listing_title: {
            type: String,
            default: ''
        },
        last_errors: {
            type: Array,
            default: () => [],
        },
        case_files: {
            type: [Object, Array]
        }
    },
    async mounted() {
        this.access.readonly = await this.checkFeatureAccess([2])
        this.access.amounts = await this.checkFeatureAccess([3])
        this.access.defaults = await this.checkFeatureAccess([4])
        this.access.amountsDefaults = await this.checkFeatureAccess([3,4])
        this.access.all = await this.checkFeatureAccess([1,3,4])
        
        if (this.type == 'defendant') {
            this.buildPaymentAccount(this.seller_accounts)
        }

        this.checkToShowCasesFileButton()
    },
    data(){
        return {
            more_details : false,
            copy_rid : false,
            some_index: 0,
            hovered: false,
            accounts: [],
            access: {
                amounts: false,
                defaults: false,
                amountsDefaults: false,
                all: false,
                readonly: false
            },
            mergedEvidence: '',
            fullmerge_key: getRandomKey(),
            showDownloadCasesFileButton: true,
        }
    },
    computed: {
        ...mapGetters(['isHolidayTheme', 'themeListingPreview']),
        ...mapState(["cancellationTokenSource"]),
        list_type() {
            return (this.type == 'small') ? 'a-list-sm' : (this.type == 'history') ? 'a-list-history' : '';
        },
        filteredCaseFiles() {
            return this.case_files?.filter(c => c?.link)
        },
        parent() {
            return this.$parent.$root.$children[0].$children.filter(child => child.$vnode.componentOptions.tag == "ar-master")[0]
        },
        action_icon() {
            if (this.field == "qflag_id")
                return this.status_icon

            return (this.action == 'delete') ? '/static/svg/Status_Delete.svg' : (this.action == 'insert') ? '/static/svg/Status_Add.svg' : '/static/svg/Status_Update.svg'
        },
        action_word() {
            // return (this.action == 'delete') ? 'Removed' : (this.action == 'insert') ? 'Added' : 'Updated'
            return ''
        },
        filtered_case_imgs() {
            return this.case_imgs.length ? this.case_imgs : [''];
        },
        c_date_filed() {
            let date = moment(this.date_filed).format("MMM DD, YYYY")
            return date != "Invalid date" ? date : null
        },
        c_status() {
            /* let status = (+this.status)

            if (this.access_level() != 7 && status < 4)
                return { value: null, label: "" }

            let c_statuses = this.case_status_options

            c_statuses = c_statuses.map(c => c.label.toLowerCase())

            return { value: status, label: c_statuses[status - 1] } */

            const status = +this.status;

            if (this.isFeatureRestricted('cm-text-case-status')) {
                return { value: null, label: '' };
            }

            const statuses = this.case_status_options.map(status => status.label.toLowerCase());
            return { value: status, label: statuses[status - 1] };
        },
        reducedPlatformImgs() {
            // Commented out, naa mas nindot na way para magcheck duplicate entries. lagot ko earl ha. hahahaha - Marvin
            // let platformsImgCopy = this.platform_imgs.platforms.reduce( (h, g) => {
            //     h[g] = g
            //     return h
            // }, {})
            
            // creates a set of unique entries and return 3 platforms
            let platformsImgCopy = [ ...new Set([...(this.platform_imgs.platforms.map(p => { 
                    let stringSplit = p.split('/')
                    let platform = this.cleanExtension(stringSplit[stringSplit.length - 1])

                    return ({ label: platform, src: p })
                }) ?? [])].map(JSON.stringify))].map(JSON.parse).filter((p, index) => index < 3)

            return platformsImgCopy
        },
        truncatedCasePlatforms() {
            const platforms = this.platform_imgs.platforms

            if (!platforms.filter(p => typeof p.count != "undefined").length)
                return `<i class="fas fa-circle-notch fa-spin"></i>`

            let platformsImgCopy = [ ...new Set([...(platforms.map(p => {
                    let stringSplit = p?.name?.split('/')
                    let platform = this.cleanExtension(stringSplit[stringSplit.length - 1])

                    return ({ label: platform, count: p.count, src: p?.name })
                }) ?? [])].map(JSON.stringify))].map(JSON.parse)
            
            platformsImgCopy = platformsImgCopy.sort((a, b) => b.count - a.count)

            let content = ''

            for (let index = 0; index < platformsImgCopy.length; index++)
                content += `<span class="d-block"><img class="platform-icon mb-1 mr-1 fadeIn slower" style="height: 25px; width: 25px;" src="${platformsImgCopy[index].src}" alt="Platform Image"> ${platformsImgCopy[index].label} - ${platformsImgCopy[index].count} defendants</span>`

            return content
        },
        /* showDefaultedFigures() {
            return !this.isFeatureRestricted('cm-text-defaulted-figures');
        }, */
        /* showNonDefaultedFigures() {
            return !this.isFeatureRestricted('cm-text-non-defaulted-figures');
        }, */
        /* accounts() {
            if (this.type !== 'defendant') {
                return [];
            }

            const accounts = this.seller_accounts;

            const nonAmountDetails = [
                { image: "/static/svg/defendants/Details_Account.svg", title: "Payment Type", key: "type", },
                { image: "/static/svg/defendants/Details_Account.svg", title: "Account Name or Email", key: "name_email", },
                { image: "/static/svg/defendants/Details_Account.svg", title: "Account Number", key: "number", }
            ];

            let nonDefaultedFigures = [
                { image: "/static/svg/Case_Frozen.svg", title: "Frozen Amount USD", key: "amount_frozen" },
                { image: "/static/svg/Case_Frozen.svg", title: "Frozen Amount CNY", key: "amount_frozen_cny" },
                { image: "/static/svg/Case_Settled.svg", title: "Agreed Settlement Amount", key: "amount_settled" },
                { image: "/static/svg/defendants/Details_Received.svg", title: "Received Settlement Amount", key: "amount_received_settlement" }
            ];

            if (!this.showNonDefaultedFigures) {
                nonDefaultedFigures = [];
            }

            let defaultedFigures = [
                { image: "/static/svg/defendants/Details_Received.svg", title: "Received Default Amount", key: "amount_received_default" },
                { image: "/static/svg/defendants/Details_Received.svg", title: "Received CTDA Default Amount", key: "amount_received_ctda_default" }
            ];

            if (!this.showDefaultedFigures) {
                defaultedFigures = [];
            }

            const mapOrder = (a, order, key) => {
                const map = order.reduce((r, v, i) => ((r[v] = i), r), {});

                return a.sort((a, b) => map[a[key]] - map[b[key]]);
            };

            const defendantDetails = [...nonAmountDetails, ...nonDefaultedFigures, ...defaultedFigures];

            const order = [
                'Payment Type',
                'Account Name or Email',
                'Account Number',
                'Frozen Amount USD',
                'Frozen Amount CNY',
                'Agreed Settlement Amount',
                'Received Settlement Amount',
                'Received Default Amount',
                'Received CTDA Default Amount'
            ];

            let stuffs = [];

            for (const account of accounts) {
                let details = [];
                for (const key in account) {
                    for (const detail of defendantDetails) {
                        if (detail.key !== key) {
                            continue;
                        }

                        const newDetail = { id: account.seller_account_id, image: detail.image, title: detail.title, value: account[key]?.trim() ?? 'Not Set'};
                        details = [...details, newDetail];
                    }
                }

                const newStuff = mapOrder(details, order, 'title');
                stuffs = [...stuffs, newStuff];
            }

            return stuffs;
        }, */
    },
    methods: {
        ...mapActions(['checkFeatureAccess',"refreshCancelToken", "cancelRequests"]),
        async checkToShowCasesFileButton() {
            if (this.type == 'case') {
                await sleep(90000)

                if (!this.filteredCaseFiles?.length)
                    this.showDownloadCasesFileButton = false
            }
        },
        cleanExtension(fileName) {
            const extensions = ['svg', 'jpg', 'jpeg', 'png', 'webp', 'gif']
            let result = fileName

            for (let i = 0; i < extensions.length; i++)
                result = result.replace(`.${extensions[i]}`, '')

            return result 
        },
        money(amount){
            if(!Number(amount)){
                return "0"
            }
            return amount.replace(".00","")
        },
        getClaimtypeIcon(claim_type) {
            const iconPath = (icon) => `/static/svg/claim-type-icons/${icon}`
            let icons = []
            
            if (claim_type.includes('Copyright'))
                icons.push(iconPath('copyright.svg'))

            if (claim_type.includes('Patent'))
                icons.push(iconPath('patent.svg'))

            if (claim_type.includes('Trademark'))
                icons.push(iconPath('trademark.svg'))

            if (claim_type == 'Unfair Competition')
                icons.push(iconPath('unfair-competition.svg'))

            if (claim_type == 'Unknown')
                icons.push(iconPath('unknown.svg'))

            return icons
        },
        preview(){
            // console.log(this.product_img)
            return this.product_img != null && this.product_img != ''
                ? `${process.env.VUE_APP_URL}/files/${this.product_img}`
                : this.themeListingPreview
        },
        casePreview(img) {
            return img != null && img != ''
                ? `${process.env.VUE_APP_URL}/files/${img}`
                : this.themeListingPreview
        },
        errorPreview() {
            return this.themeListingPreview
        },
        access_level() {
            let access_level = 3
            if(location.pathname.includes("verification") && localStorage.getItem("ver") != null){
                return JSON.parse(localStorage.getItem("ver")).access_level
            } else if (location.pathname.includes("lawfirm") && localStorage.getItem("lawfirm") != null) {
                return JSON.parse(localStorage.getItem("lawfirm")).access_level
            }
            return access_level
        },
        copytoClipboard(str = "", prop_name = 'listing id') {
            if (str != "") {
                let modal = []
                let container = (modal = document.querySelectorAll('.modal.in, .modal.show')).length ? modal : document
                const el = document.createElement('textarea')
                el.value = str
                el.setAttribute('readonly', '')
                el.style.position = 'absolute'
                el.style.left = '-9999px'
                container[modal.length ? 0 : "body"].appendChild(el)
                el.select()
                document.execCommand('copy')
                container[modal.length ? 0 : "body"].removeChild(el)

                // call the snackbar when copied
                this.parent.showSnackbar(`You copied ${prop_name}: ${str}`, false, 'success', 'fas fa-check')
            }
        },
        isCopying(status) {
            this.copy_rid = status
        },
        async buildPaymentAccount(accounts) {
            let stuffs = []
            let keys_to_get = [
                {image: "/static/svg/defendants/Details_Account.svg", title: "Payment Type", key: "type"},
                {image: "/static/svg/defendants/Details_Account.svg", title: "Account Name or Email", key: "name_email"},
                {image: "/static/svg/defendants/Details_Account.svg", title: "Account Number", key: "number"}
            ]
            let amounts_keys = [
                {image: "/static/svg/Case_Frozen.svg", title: "Frozen Amount USD", key: "amount_frozen"},
                {image: "/static/svg/Case_Frozen.svg", title: "Frozen Amount CNY", key: "amount_frozen_cny"},
                {image: "/static/svg/Case_Settled.svg", title: "Agreed Settlement Amount", key: "amount_settled"},
                {image: "/static/svg/defendants/Details_Received.svg", title: "Received Settlement Amount", key: "amount_received_settlement"}
            ]
            let amount_default_keys = [
                {image: "/static/svg/defendants/Details_Received.svg", title: "Received Default Amount", key: "amount_received_default"},
                {image: "/static/svg/defendants/Details_Received.svg", title: "Received CTDA Default Amount", key: "amount_received_ctda_default"}
            ]
            const mapOrder = (a, order, key) => {
                const map = order.reduce((r, v, i) => ((r[v] = i), r), {})
                return a.sort((a, b) => map[a[key]] - map[b[key]])
            }

            let things = this.access.defaults 
                ? [...keys_to_get, ...amounts_keys, ...amount_default_keys]
                : this.access.amounts
                    ? [...keys_to_get, ...amounts_keys]
                    : keys_to_get
            
            for (let index = 0; index < accounts.length; index++) {
                let account = accounts[index]
                keys_to_get = things
                let details = []
                let order = ["Payment Type", "Account Name or Email", "Account Number", "Frozen Amount USD", "Frozen Amount CNY", "Agreed Settlement Amount", "Received Settlement Amount", "Received Default Amount", "Received CTDA Default Amount"]
                
                for (let [key, val] of Object.entries(account)) {
                    keys_to_get.forEach(item => {
                        if (item.key == key) {
                            details.push({
                                id: account.seller_account_id,
                                image: item.image,
                                title: item.title,
                                value: val?.trim() || "Not Set",
                            })
                            return
                        }
                    })
                }
                stuffs.push(mapOrder(details, order, 'title'))
            }
            this.accounts = stuffs
        },
        // only for listings full merge
        // from listingModal "preview" function
        previewFullMerge(name, link = false) {
            let filepath = `${process.env.VUE_APP_URL}/files/`

            if (link == true)
                return filepath + name

            if (typeof name == 'undefined') return ""

            if (name.includes("Missing")) {
                var datum = name.split('|')
                datum = datum[0].trim().split('.')

                return ["png", "jpg", "jpeg"].includes(datum[1].trim().toLowerCase()) 
                    ? '/static/svg/IMG_BROKEN.svg'
                    : '/static/svg/PDF_BROKEN.svg'
            } else {
                var ext = name.split('.')

                if (name != "") {
                    // return value while evaluating condition
                    return ["png", "jpg", "jpeg"].includes(ext[1].trim().toLowerCase()) // if filepath is image
                        ? name != "" // if 'name' is not null
                            ? filepath + name // true: return filename with filepath
                            : '/static/svg/IMG Default.svg' // false: return IMG Default
                        : '/static/svg/PDF Default.svg' // false: return PDF Default
                }

                return '/static/svg/IMG_BROKEN.svg'
            }
        },
        async getUploadedEvidences(id) {
            // this.parent.showSnackbar(`Fetching Full Merge... Please wait.`, true)
            this.parent.showSnackbar(`Fetching Evidence... Please wait.`, true)
            let evidences = await this.returnCurrentEvidences(id)
            if (evidences.length == 0) {
                this.mergedEvidence = 'missing'
            }

            for (let key in evidences) {
                if (key.toLowerCase().split(" ").some(r => ["merge"].includes(r))) {
                    this.mergedEvidence = {"image":evidences[key],"type":key}
                    break;
                } else {
                    this.mergedEvidence = 'missing'
                }
            }
            
            if(this.mergedEvidence == '' || this.mergedEvidence == 'missing' || this.mergedEvidence?.image?.includes('Missing')) {
                // this.parent.showSnackbar(`It seems that the File is Missing for Full Merge.`, false, 'info', 'fas fa-info-circle')
                this.parent.showSnackbar(`It seems that the File is Missing for Evidence.`, false, 'info', 'fas fa-info-circle')
                return
            }

            const url = this.previewFullMerge(this.mergedEvidence.image, true)
            // this.parent.showSnackbar(`Successfully Fetched Full Merge.`, false, 'success', 'fas fa-check')
            this.parent.showSnackbar(`Successfully Fetched Evidence.`, false, 'success', 'fas fa-check')
            window.open(url, '_blank')
        },
        // A copy from Listings "current" function but it will return the "evidences" instead of passing whole data to "listing"
        returnCurrentEvidences: async function (id) {
            this.cancelRequests();
            this.refreshCancelToken();
            let evidences = []
            let promise = await new Promise(resolve => {
                let headers = JSON.parse(localStorage.getItem('headers'))
                headers.cancelToken = this.cancellationTokenSource.token;
                // headers.headers.where = `{"id" : ${id}}`
                axios.get(`${process.env.VUE_APP_URL}/listings/${id}`, headers).then(
                    response => {
                        evidences = response.data.data[0].evidences
                        resolve(evidences)
                    })
            })
            return promise
        },
    },
    watch: {
        mergedEvidence() {
            this.fullmerge_key = getRandomKey()
        },
    }
}
</script>

<style scoped>
    .a-list {
        -webkit-transition: background .2s ease, border-color .5s ease;
        transition: background .2s ease, border-color .5s ease;
        text-align: left;
        cursor: pointer;
        border: 1px solid transparent;
    }

    .a-list.checked {
        background: #EAF0FC 0% 0% no-repeat padding-box;
        border-color: #2D599E;
    }

    .info-status {
        display: inline-block;
        height: 25px;
        border-radius: 25px;
        font-size: 12px;
        line-height: 24px;
        color: #ffffff;
        text-align: center;
        position: relative;
        top: -4px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .case-status {
        font-size: 9px;
        height: 18px;
        line-height: 18px;
        padding-left: 15px;
        padding-right: 15px;
        border: 1px solid #fff;
        background: #7005ae;
    }

    .case-status.case-file {
        background: #8D9DBC;
        padding-left: 27px;
        margin-left: -20px;
        position: relative;
        min-width: 75px;
    }

    .case-status.case-file.available {
        background: #3B5998;
        -webkit-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
    }

    .case-status.download-btn.available {
        background: #28a745;
    }

    .case-status.case-file.available:hover {
        opacity: .8;
    }

    .case-status.status-1 {
        background: #8b9dc3;
    }

    .case-status.status-2 {
        background: #3C97DC;
    }

    .case-status.status-3 {
        background: #f3a17a;
    }

    .case-status.status-4 {
        background: #ff802b;
    }

    .case-status.status-5 {
        background: #23c235;
    }

    .case-status.status-6 {
        background: #a94b20;
    }

    .case-status.status-7 {
        background: #172b55;
    }

    .case-status.status-8 {
        background: #7005ae;
    }

    .case-status.status-9 {
        background: #18151a;
    }

    .case-status.status-10 {
        background: #6C6960;
    }

    .a-list.checked .img-container .custom-control {
        opacity: 1;
    }

    .a-list.is-invalid {
        background: #F2C6C7;
        border: 1px solid #DC3C40;
    }

    .a-list .card-body {
        padding: 8px 12px 8px 8px;
    }

    .a-list.defendant-card .card-body {
        /* min-height: 386px; */
        min-height: 0;
    }

    .a-list .a-progress-sm {
        margin-bottom: 8px;
        margin-top: 3px;
        width: 260px;
    }

    .a-list .img-container {
        /* background: #1B1C1D; */
        position: relative;
        border-radius: 4px;
        margin-right: 18px;
        overflow: hidden;
        height: 112px;
        width: 154px;
        float: left;
    }

    .a-list .img-container img[lazy=loading] {
        -webkit-transition: opacity 1s ease;
        transition: opacity 1s ease;
        opacity: 0;
    }

    .a-list .img-container img[lazy=loaded] {
        opacity: 0.8;
    }

    .a-list .img-container img {
        -webkit-transition: opacity 1s ease;
        transition: opacity 1s ease;
        opacity: 0.8;
    }

    .a-list .img-container img:hover {
        opacity: 1;
    }

    .a-list .img-container .custom-control {
        -webkit-transition: opacity .2s ease;
        transition: opacity .2s ease;
        position:absolute;
        z-index: 2;
        /* opacity: 0; */
        left: 8px;
        top: 8px;
    }

    /* .a-list:hover .img-container .custom-control {
        opacity: 1;
    } */

    .a-list .doe-no {
        background-color:#007bff;
        color:white;
        padding:5px 8px;
        position: relative;
        top: -2px;
        border-radius:30px;
        font-size:13px;
        margin-right:5px;
    }

    .a-list .grid-seller-name {
        max-width: 76%;
        display: inline-block;
        vertical-align: middle;
    }

    .a-list .seller-info {
        float: right;
        width: calc(100% - 172px);
        /* position: relative; */
    }

    .a-list h2 {
        letter-spacing: 0.19px;
        color: #000000DE;
        margin-bottom: 5px;
        font-weight: 400;
        margin-top: 5px;
        font-size: 20px;
    }

    .a-list .status-badge {
        background: #6EB4F9 0% 0% no-repeat padding-box;
        position: relative;
        border-radius: 3px;
        text-align: center;
        padding: 3px 8px 1px;;
        color: #FFFFFF;
        font-size: 12px;
        top: -4px;
        min-height: 16px;
        min-width: 20px;
        display: inline-block;
    }

    .a-list .status-badge.green {
        background: #29ABA2 0% 0% no-repeat padding-box;
    }

    .a-list .status-badge.claimtype-icon {
        padding: 2px 2px 0 2px;
    }

    .a-list .status-badge.claimtype-icon img {
        position: relative;
        top: -1px;
    }

    .rate-badge {
        font-size: 11px;
        color: white;
        width: 100%;
        text-align: center;
        padding-top: 4px;
        padding-bottom: 4px;
        border-radius: 12px;
        margin-bottom: 12px;
    }

    .gross {
        background-color: #F07241 !important;
    }

    .net {
        background-color: #F0AA41;
    }

    .default {
        background-color: #C04848;
    }

    .deduction {
        background-color: #A54284
    }

    .a-list .id-status {
        letter-spacing: 0.4px;
        color: #2D599E;
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 6px;
        flex-wrap: wrap;
        width: calc(100% - 40px);
        margin: 7px 0 8px;
    }

    .a-list .seller-item-details {
        letter-spacing: 0.25px;
        color: #000000;
        font-size: 14px;
        width: calc(100% - 63px);
        min-height: 36px;
        margin-bottom: 0;
    }
    
    .a-list .seller-item-details.lawfirm {
        padding-top: 20px;
    }

    .a-list .platform-icon {
        border-radius: 4px;
        position: absolute;
        height: 32px;
        width: 32px;
        right: 12px;
        bottom: 18px;
    }

    .a-list .accounts-con {
        height: 247px;
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
    }

    .a-list .accounts-con.no-defaults {
        height: 194px;
    }

    .a-list .accounts-con.no-amounts {
        height: 127px;
    }

    .a-list .accounts-con.no-account {
        height: auto;
    }

    .a-list .accounts-con .table tbody tr td {
        font-size: 12px; 
        font-weight: 600;
        color: #141517;
        letter-spacing: 0.4px;
        padding-top: 5px;
        padding-bottom: 2px;
    }

    .a-list .accounts-con .table tbody tr td.header-label {
        font-weight: 400;
        color: #515365;
    }

    .a-list .accounts-con .table tbody tr td.header-label img {
        width: 12px;
        height: 12px;
    }

    .a-list .status-history-btn {
        border-radius: 4px;
        position: absolute;
        right: 8px;
        top: 15px;
    }

    .a-list-sm {
        -webkit-transition: background .2s ease;
        transition: background .2s ease;
        text-align: left;
        box-shadow: none;
        cursor: pointer;
        height: 72px;
    }

    .a-list-sm .card-body {
        padding: 8px;
    }

    .a-list-sm .img-container {
        background: #1B1C1D;
        border-radius: 4px;
        position: relative;
        margin-right: 28px;
        overflow: hidden;
        height: 56px;
        width: 56px;
        float: left;
    }

    .a-list:hover, .a-list-sm:hover {
        background: #F5F5F5;
    }

    .a-list-sm h2 {
        letter-spacing: 0.15px;
        color: #000000DE;
        line-height: 48px;
        font-weight: 400;
        font-size: 16px;
    }

    .a-list-sm + .checked {
        background: #EAF0FC 0% 0% no-repeat padding-box;
    }

    .a-list:not(.a-list-history) .img-container img,
    .a-list-sm .img-container img,  
    .a-list:not(.a-list-history) .img-container object{
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: transalte(-50%, -50%) scale(1);
        position: absolute;
        /* width: 100%; */
        height: 100%;
        left: 50%;
        top: 50%;
    }

    .a-list-history {
        -webkit-transition: background .2s ease;
        transition: background .2s ease;
        letter-spacing: 0.21px;
        padding-top: 8px;
        text-align: left;
        box-shadow: none;
        min-height: 78px;
        font-size: 12px;
        cursor: pointer;
        height: auto;
    }

    .a-list-history .card-body {
        padding: 8px;
    }

    .a-list-history .img-container {
        background: none;
        border-radius: 4px;
        position: relative;
        margin-right: 20px;
        overflow: hidden;
        height: 24px;
        width: 24px;
        float: left;
    }

    .a-history-con {
        width: calc(100% - 44px);
        word-break: break-all;
        float: right;
    }

    .a-list-history p {
        margin-bottom: 5px;
    }

    .a-list-history p span {
        font-weight: 500;
    }

    .a-list-history .list-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .a-list-history .list-footer .list-footer-status {
        color: #6EB4F9;
    }

    .a-list-history h5 {
        letter-spacing: 0.15px;
        color: #000000DE;
        line-height: 56px;
        font-weight: 400;
        font-size: 16px;
    }

    .a-list .case-platform-con {
        position: absolute;
        right: 0;
        bottom: -2px;
    }

    .a-list .case-platform-con .platform-icon {
        position: relative;
        margin-right: 2px;
        height: 25px;
        width: 25px;
    }

    .a-list .case-list-btns {
        position: absolute;
        right: 12px;
        top: 12px;
    }

    .a-list .case-list-btns .case-btn {
        -webkit-transition: opacity 0.2s ease;
        transition: opacity 0.2s ease;
        position: relative;
        margin-right: 5px;
    }

    .a-list .case-list-btns .case-btn:last-child {
        margin-right: 0;
    }

    .a-list .case-list-btns .case-btn:hover,
    .a-list .lawfirm-btn:hover {
        opacity: 0.5;
    }

    .a-list .img-container-case {
        display: flex;
        flex-flow: column wrap;
    }

    .a-list .img-container-case .case-image-addon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,0.5);
        color: #fff;
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        padding-top: 42%;
        /* line-height: 53px; */
        letter-spacing: 1.7px;
    }

    .a-list .img-container-case::before,
    .a-list .img-container-case::after {
        content: "";
        flex-basis: 100%;
        width: 0;
        order: 2;
    }

    .a-list .img-box {
        position: relative;
        overflow: hidden;
    }

    .a-list .img-box:nth-child(2n+1),
    .a-list .img-box:nth-child(2n) {
        width: 50%;
        height: 50%;
    }

    .a-list .img-box:nth-child(2n) {
        order: 1;
    }

    .a-list .img-box:nth-child(2n) {
        order: 2;
    }

    .a-list .img-box-1:nth-child(2n) {
        order: 1;
        width: 100%;
        height: 100%;
    }

    .a-list .img-box-2:nth-child(2n+1) {
        order: 1;
        width: 50%;
        height: 100%;
    }

    .a-list .img-box-2:nth-child(2n) {
        order: 2;
        width: 50%;
        height: 100%;
    }

    .a-list .img-box.img-box-2:nth-child(2n+1) object,
    .a-list .img-box.img-box-2:nth-child(2n+1) img,
    .a-list .img-box.img-box-2:nth-child(2n) object,
    .a-list .img-box.img-box-2:nth-child(2n) img {
        -webkit-transform: translate(-50%, -50%) scale(3);
        transform: translate(-50%, -50%) scale(3);
    }

    .a-list .img-box-3:nth-child(3n+2),
    .a-list .img-box-3:nth-child(3n+1) {
        order: 1;
        width: 50%;
        height: 50%;
    }

    .a-list .img-box-3:nth-child(3n) {
        order: 2;
        width: 50%;
        height: 100%;
    }

    .a-list .img-box.img-box-3:nth-child(3n) img,
    .a-list .img-box.img-box-3:nth-child(3n) object {
        -webkit-transform: translate(-50%, -50%) scale(3);
        transform: translate(-50%, -50%) scale(3);
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
    }

    .a-list .img-container-case .img-box img,
    .a-list .img-container-case .img-box object {
        -webkit-transform: translate(-50%, -50%) scale(2);
        transform: translate(-50%, -50%) scale(2);
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
    }

    .list-campaign-img {
        -webkit-transition: .5s ease;
        transition: .5s ease;
        position: absolute;
        bottom: 15px;
        left: 15px;
        width: 40px;
        height: 40px;
        border: 1px solid #ccc;
        border-radius: 2px;
    }

    .researcher-details {
        text-transform: uppercase;
        letter-spacing: 0.5px;
        position: absolute;
        font-weight: 500;
        color: #2D599E;
        right: 10px;
        top: 15px;
    }

    .a-list .case-info {
        font-size: 13px;
    }

    .a-list .defendant-details-con {
        -webkit-transition: height 0.3s ease;
        transition: height 0.3s ease;
        position: relative;
        height: 0;
        overflow: hidden;
    }

    .a-list .defendant-details-con.expanded {
        height: 180px;
    }

    .a-list .defendant-details-con .details-con {
        padding-top: 10px;
        height: 180px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .a-list .defendant-details-con::before {
        content: "";
        position: absolute;
        width: 98%;
        height: 1px; 
        background: #ccc;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .a-list .defendant-details-toggle {
        -webkit-transition: 0.2s ease;
        transition: 0.2s ease;
        color: #2D599E !important;
        position: absolute;
        right: 11px;
        top: 102px;
    }

    .a-list .defendant-details-toggle:hover,
    .a-list.accounting-card .details-toggle:hover {
        opacity: 0.8;
    }

    .a-list .defendant-details-toggle i,
    .a-list.accounting-card .details-toggle i {
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
    }

    .a-list .defendant-details-toggle i.expanded,
    .a-list.accounting-card .details-toggle i.expanded {
        -webkit-transform: rotate(180deg);
        transform        : rotate(180deg);
    }

    .a-list .main-seller-account {
        border-left: 4px solid rgb(41, 171, 162);
    }

    .a-list .main-seller-account-grid {
        color: rgb(41, 171, 162);
    }

    .a-list .defendant-details-con .a-modal-seller-info-item {
        min-height: 33px;
        padding-top: 9px !important;
        margin-bottom: 0;
    }

    .a-list.accounting-card {
        /* max-height: 314px; */
        max-height: 255px;
        overflow: hidden;
        -webkit-transition: max-height ease 0.5s;
        transition: max-height ease 0.5s;
    }

    .a-list.accounting-card .table tr td {
        font-size: 12px;
        padding-top: 6px;
        padding-bottom: 6px;
        letter-spacing: 0.4px;
    }

    .a-list.accounting-card .table tr td b.judge-name {
        width: 220px;
    }

    .a-list.accounting-card .table tr td.header-label,
    .a-list.accounting-card .table th {
        font-size: 12px;
        color: #515365;
        letter-spacing: 0.4px; 
    }

    .a-list.accounting-card.expanded {
        border-color: #480048;
        background: #ece8f0;
        max-height: 800px;
    }

    .a-list .hovered-options {
        -webkit-transition: .3s ease;
        transition: .3s ease;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 4px;
        position: absolute;
        overflow: hidden;
        max-height: 0;
        width: 100%;
        z-index: 2;
        opacity: 0;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
    }

    .a-list .hovered-option-con {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        position: absolute;
        text-align: center;
        width: 100%;
        left: 50%;
        top: 50%;
    }

    .a-list .hovered-option-con .actions {
        -webkit-transition: opacity .5s ease;
        transition: opacity .5s ease;
        display: inline-block;
        margin: 0;
        opacity: 1;
    }

    .a-list .hovered-option-con .actions:hover {
        opacity: 0.8;
    }

    .a-list .hovered-options.hovered {
        border-color: #480048;
        background: #ece8f025;
        max-height: 100%;
        opacity: 1;
    }

    .a-list .non-hover-options-con {
        min-width: 97px;
        position: absolute;
        text-align: center;
        right: 109px;
        top: -10px;
    }

    .a-list .non-hover-options-con .actions {
        display: inline-block;
        width: 42px;
    }

    .a-list .non-hover-options-con .actions img {
        width: 100%;
    }

    .exports-item .img-container {
        width: 24px;
        height: 24px;
        top: 50%;
    }

    .exports-item.a-list {
        height: initial;
    }

    .exports-item h2 {
        font-size: 12px;
        line-height: 22px;
    }

    .list-status-truncate {
        vertical-align: middle;
        max-width: 150px;
        display: inline-block;
    }

    .a-list .misc-details {
        padding: 2px 6px 1px;
        border: 1px solid #2D599E;
        display: inline-block;
        border-radius: 4px;
    }
    .a-list .misc-details.export-pdf {
        border-color: #dc3545;
        background-color: #ecbabf;
        color: #dc3545;
        font-weight: bold;
    }

    .a-list .misc-details.researcher {
        border-color: #dc3545;
        color: #dc3545;
    }

    .a-list .misc-details.qa {
        border-color: rgb(28, 152, 144);
        color: rgb(28, 152, 144);
    }
    
    .a-list .misc-details.casenum {
        padding: 0 0 0 6px;
    }

    /* new suggested design for listings */
    .a-list .id-status.new-design {
        width: calc(100% - 205px);
    }
    .a-list .right-side-position {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 3px;

        position: absolute;
        right: 0;
        top: 0;
        padding-top: 45px;
        padding-right: 16px;
        letter-spacing: 0.4px;
        color: #2D599E;
        font-size: 12px;
    }
    .a-list .new-goto {
        display: flex;
        align-items: center;
        gap: 6px;

        position: absolute;
        top: 45px;
        left: 180px;
        /*padding-top: 45px;*/
        /*padding-left: 180px;*/

        letter-spacing: -0.2px;
        color: #2D599E;
        font-size: 12px;
    }
    .a-list .new-goto a {
        color: #2D599E;
    }
    .a-list .new-goto .special {
        background: #2D599E;
        color: #FFFFFF;
    }
    .a-list .new-goto .special:hover {
        background-color: #3E88DA29;
        border: 1px solid #3E88DA24;
        color: #2D599E;
    }

    .a-list .new-goto .simple:hover {
        background-color: #3E88DA29;
        border: 1px solid #3E88DA24;
        color: #2D599E;
    }
    .misc-details.status-monitoring {
        color: #FF5722;
        background-color: #FF572229;
        border: 1px solid #FF572233;
    }
    .misc-details.status-rejected {
        color: #DC3C40;
        background-color: #F5686C38;
        border: 1px solid #DC3C40;
    }
    .misc-details.status-accepted {
        color: #29ABA2;
        background-color: #DFF6F4;
        border: 1px solid #29ABA2;
    }
    /* -------------- end for new suggested design */

    /* auto-request styles */
    .a-list .auto-request{
        padding: 0px !important;
    }
    .card-body .auto-request  {
        padding: 0px !important;
    }
    .a-list .id-status.auto-request {
        margin: 0px 0px 15px 0px;
        /* flex-direction: column; */
        align-items: flex-start;
        justify-content: flex-start;
        /* width: calc(100% - 280px); */
    }
    .request-list-item-container {
        min-height: 176px;
        padding: 16px;
        width: 100%;
        border: 1px solid transparent;
        position: relative;
    }
    .request-list-item-container:hover {
        background: #ebf4ff;
        border: 1px solid #ebf4ff;
    }

    .auto-request-status {
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: #595959;
        font-size: 10px;
        font-weight: 500;
        padding: 2px 5px 1px;
        border-radius: 4px;
        border: 1px solid #70707054;          
        cursor:auto;
        width: auto;
    }
    .auto-request-status img {
        width: 12px;
        height: 12px;
        margin-right: 5px;
    }
    .auto-request-status.success {
        background-color: #79BD9A;
        color: #FFFFFF;
        border:none;
    }
    .auto-request-status.processing {
        background-color: #3E88DA;
        color: #FFFFFF;
        border:none;
    }
    .auto-request-status.pending {
        background-color: #F3A17A;
        color: #FFFFFF;
        border:none;
    }
    .auto-request-status.failed {
        background-color: #EE8E90;
        color: #FFFFFF;
        border:none;
    }
    .auto-request-status.completed-with-issues {
        background-color: #fbdede;
        /* color: #FFFFFF; */
        /* border:none; */
    }

    .request-list-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        width: 100%;
        height: auto;
    }
    .request-list-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        /* gap: 5px; */
    }
    .request-list-error {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        /* gap: 5px; */

        width: 100%;
        height: auto;
        padding: 10px;

        margin-top: 10px;

        background: #fbdede;
        border: 1px solid #70707054;
        border-radius: 4px;

        font-size: 12px;
        font-weight: 500;
        color: #333333DE;
    }
    .request-list-error span {
        height: 5px;
        width: 5px;
        background: #333333DE;
        border-radius: 100%;
    }
    .request-list-error-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        /* gap: 10px; */
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-all;
        hyphens: auto;
        white-space: pre-wrap;
        margin-left: 15px;
    }
    .request-list-desc {
        font-size: 12px;
        font-weight: 500;
        color: #333333DE;
        text-decoration: underline;
    }
    /* -------------------- */


    .card-con #case--name {
        max-width: 200px;
    }

    @media screen and (max-width: 1600px) {
        .card-con .case-info b {
            margin-bottom: 5px !important;
        }

        .card-con #case--name {
            max-width: 70%;
            margin-bottom: 5px !important;;
        }
        
        .card-con #case--statuses {
            display: block;
        }

        .card-con .case-info .d-inline-flex {
            flex-wrap: wrap;
            padding-right: 117px;
            width: 100%;
        }

        .card-con #case--statuses > span.case-status {
            margin-left: 0 !important;
        }
    }
</style>
