<template>
    <div class="card a-list-item" 
        :class="{
            'inactive': !active,
            'info-pending': type == 'info-pending',
            'info-ongoing': type == 'info-ongoing',
            'info-invalid': type == 'info-invalid',
            'info-unchecked': type == 'info-unchecked',
            'info-done': type == 'info-done',
            'info-admin': type == 'location'}"
        :style="`${clickableList ? 'cursor: pointer': ''}`"
        style="position:relative;"
        @click="clickableList && !has_checkbox && $emit('click')"
        :id="id">
        <template v-if="type.includes('info-')">
            <ar-checkbox style="position:absolute; top:50%;left:20px;transform: translateY(-50%);" v-if="has_checkbox" :id="clid" :value="clid" name="client_listings_data_checker"  @change="$emit('change',clid)"></ar-checkbox>
            <div @click="$emit('click')" class="card-body pb-2 pt-3 pr-3" :class="[!has_checkbox ??'pl-3']" :style="[has_checkbox ? {'padding-left': '60px'}: '']">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="left-con">
                        <img :src="info.image" class="info-image" alt="icon">
                        <label class="info-label">{{ info.label }}</label>
                    </div>
                    <div class="d-flex justify-content-between align-items-center right-con">
                        <span class="info-status d-block" :class="{'ongoing': status == 'Monitoring'}" :title="status"> 
                            {{ status == 'Qualified' ? 'For Review' : status == 'Researched' ? 'For Quality Checking' : status }} 
                        </span>

                        <span v-if="info.datetime" class="info-date d-block text-uppercase">{{ info.datetime }}<img src="/static/svg/notifications/Sort_Date.svg" alt="icon"></span>
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="type === 'location'">
            <div class="card-body" style="padding: 15px !important">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <div class="custom-control custom-checkbox d-inline-block" @click="$emit('box-click')"> 
                            <input 
                                class     ="custom-control-input" 
                                type      ="checkbox" 
                                :id       ="`item-checkbox-${id}`"
                                :checked  ="checked"
                                :disabled ="disabled"
                                @change   ="$emit('change',$event.target.value)">
                                <label :for="`item-checkbox-${id}`" class="custom-control-label">&nbsp;</label>
                        </div>
                        <label class="custom-control-label">{{ label }}</label>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="info-status region d-block">{{ region }}</span>
                        <span class="info-status country d-block ml-2 mr-4">{{ country }}</span>
                        <span class="d-block">
                            <slot name="list-buttons"></slot>
                        </span>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <div class="custom-control custom-checkbox d-inline-block" @click="$emit('box-click')"> 
                            <input 
                                class     ="custom-control-input" 
                                type      ="checkbox" 
                                :id       ="`item-checkbox-${id}`"
                                :checked  ="checked"
                                :disabled ="disabled"
                                @change   ="$emit('change',$event.target.value)">
                            <label :for="`item-checkbox-${id}`" class="custom-control-label">{{ label }}</label>
                        </div>
                        <i class="fas fa-chevron-right ml-3 position-relative list-dropdown" :class="{'is_toggled': dropdown}" v-if="has_arrow && has_dropdown"></i>
                    </div>
                    <div>
                        <ar-chip v-if="chip_label" @click="has_dropdown ? dropdown = !dropdown : $emit('chip-click')" class="mr-4">{{ chip_label }}</ar-chip>
                        <slot name="list-buttons"></slot>
                    </div>
                </div>
            </div>
            <template v-if="has_dropdown">
                <hr class="mt-0 mb-0 mr-2 ml-2 position-relative list-divider">
                <div class="dropdown-content" :class="{'is_toggled': dropdown}">
                    <div class="p-3">
                        <slot name="dropdown-content"></slot>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    import moment from 'moment'
    import checkbox from './Checkboxes'
    export default {
        name: "ar-list-item",
        components: {
            ArChip: () => import('./Chip'),
            'ar-checkbox': checkbox,
        },
        props: {
            label: {
                default: ""
            },
            id: {
                default: ""
            },
            checked: {
                default: false
            },
            has_dropdown: {
                type: Boolean,
                default: false
            },
            has_arrow: {
                type: Boolean,
                default: true
            },
            chip_label: {
                default: ""
            },
            active: {
                default: 1
            },
            disabled: {
                default: false
            },
            clickableList: {//Use this prop when you don't want to make parent div bind with click event
                type: Boolean,
                default: true
            },
            type: {
                type: String,
                default: ""
            },
            datetime: {
                type: String,
                default: ""
            },
            region: {
                type: String,
                default: ''
            },
            country: {
                type: String,
                default: ''
            },
            info_data: {
                type: Object,
                default: () => ({ pending: "Unchecked", ongoing: "Checking", invalid: "In the System", done: "Done" })
            },
            status: {
                type: String,
                default: 'Work In Progress'
            },
            has_checkbox: {
                type: Boolean,
                default: false
            },
            clid: {
                default: '' 
            },
        },
        computed: {
            info() {
                let info = {
                    label: this.label,
                    image: "",
                    status: "",
                    datetime: moment(this.datetime).format('DD MMM')
                }

                switch (this.type) {
                    case "info-done": {
                        info.image = "/static/svg/counterfeit/Done.svg",
                        info.status = this.info_data.done
                    }
                    break;
                    case "info-invalid": {
                        info.image = "/static/svg/counterfeit/In the system.svg",
                        info.status = this.info_data.invalid
                    }
                    break;
                    case "info-ongoing": {
                        info.image = "/static/svg/counterfeit/Checking.svg",
                        info.status = this.info_data.ongoing
                    }
                    break;
                    default: {
                        info.image = "/static/svg/counterfeit/Unchecked.svg",
                        info.status = this.info_data.pending
                    }
                    break;
                }

                return info;
            }
        },
        data() {
            return {
                dropdown: false
            }
        }
    }
</script>

<style scoped>
    .a-list-item {
        background: #fff;
        -webkit-transition: background 0.3s ease;
        transition: background 0.3s ease;
    }

    .a-list-item:hover {
        background: #eee;
    }

    .a-list-item.inactive {
        background: #DC1A1A1C;
    }

    .a-list-item.inactive .custom-control-label::before {
        border: 2px solid #f33232;
        background: #fff;
    }

    .a-list-item .custom-control-label::before {
        border-color: #ccc;
        background-color: transparent;
        border-radius: 100%;
        width: 27px;
        height: 27px;
        top: -2px;
        box-shadow: 0 0 0;
        cursor: pointer;
    }

    .a-list-item .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #ccc;
        background-color: transparent;
        border-radius: 100%;
        width: 27px;
        height: 27px;
        top: -2px;
    }

    .a-list-item .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
        background-image: url('/static/svg/Active.svg');
    }

    .custom-control-label::after {
        width: unset;
        height: unset;
    }

    .a-list-item .custom-checkbox .custom-control-label::after {
        width: 27px !important;
        height: 27px !important;
        background-repeat: no-repeat;
        background-size: cover;
        top: -2px !important;
        cursor: pointer;
    }

    .a-list-item .custom-control-label {
        text-align: left;
        letter-spacing: 0.28px;
        font-weight: 500;
        font-size: 16px;
        color: #515365;
        opacity: 1;
        top: 2px;
    }

    .a-list-item .list-dropdown {
        top: 3px;
        -webkit-transition: transform .5s ease;
        transition: transform .5s ease;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    .a-list-item .list-dropdown.is_toggled {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .a-list-item .list-divider {
        top: 1px;
    }

    .a-list-item .dropdown-content {
        -webkit-transition: .5s ease;
        transition: .3s ease;
        position: relative;
        overflow: hidden;
        height: 0;
        top: 1px;
    }

    .a-list-item .dropdown-content.is_toggled {
        height: 180px;
        overflow: auto;
    }

    .a-list-item .list-btn {
        -webkit-transition: opacity 0.5s ease;
        transition: opacity 0.5s ease;
        display: inline-block;
    }

    .a-list-item .list-btn:hover {
        opacity: 0.6;
    }

    .a-list-item .left-con {
        width: calc(100% - 267px);
    }

    .a-list-item .info-image {
        height: 25px;
        width: 25px;
        display: inline-block;
        border-radius: 25px;
        margin-right: 18px;
        position: relative;
        top: -6px;
    }

    .a-list-item .info-label {
        text-align: left;
        letter-spacing: 0.28px;
        font-weight: 500;
        font-size: 14px;
        color: #515365;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap;
        margin-bottom: 0;
        width: calc(100% - 60px);
    }

    .a-list-item .info-status {
        display: inline-block;
        min-width: 98px;
        padding: 0 10px;
        height: 25px;
        border-radius: 25px;
        font-size: 12px;
        line-height: 24px;
        color: #ffffff;
        text-align: center;
        position: relative;
        top: -4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .a-list-item .region, .a-list-item .country {
        top: 0px;
    }

    .a-list-item.info-pending {
        border-left: 5px solid #8b9dc3;
    }

    .a-list-item.info-pending .info-status {
        background: #8b9dc3;
    }

    .a-list-item.info-ongoing {
        border-left: 5px solid #f3a17a;
    }

    .a-list-item.info-ongoing .info-status {
        background: #f3a17a;
    }

    .a-list-item.info-invalid {
        border-left: 5px solid #ec7174;
    }

    .a-list-item.info-invalid .info-status {
        background: #ec7174;
    }

    .a-list-item.info-unchecked {
        border-left: 5px solid #b4b1b1;
    }

    .a-list-item.info-unchecked .info-status {
        background: #b4b1b1;
    }

    .a-list-item.info-done {
        border-left: 5px solid #29ABA2;
    }

    .a-list-item.info-done .info-status {
        background: #29ABA2;
    }

    .a-list-item.info-done .info-status.ongoing {
        background: #f3a17a;
    }

    .a-list-item.info-admin {
        border-left: 5px solid #480048;
    }

    .a-list-item.info-admin .region {
        background: #f07241c4 0% 0% no-repeat padding-box;
    }

    .a-list-item.info-admin .country {
        background: #601848bf 0% 0% no-repeat padding-box;
    }

    .a-list-item .info-date {
        position: relative;
        top: -3px;
        font-size: 10px;
        color: #3c97dc;
    }

    .a-list-item .info-date img {
        position: relative;
        top: -1px;
        margin-left: 7px;
    }

    .a-list-item .right-con {
        width: 195px;
    }
</style>