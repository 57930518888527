<script>
export default {
    name: 'list-chart',
    data() {
        return {
            mock_data: [{
                src: '/static/svg/Classes/Cloth.svg',
                name: 'Clothing (Hoody, jacket, caps, shirts, bags)',
                class: 'CLASS 25'
            }, {
                src: '/static/svg/Classes/Toy.svg',
                name: 'Toys',
                class: 'CLASS 28'
            }, {
                src: '/static/svg/Classes/Blanket.svg',
                name: 'blankets, cushion cover',
                class: 'CLASS 24'
            }, {
                src: '/static/svg/Classes/Backpack.svg',
                name: 'backpacks',
                class: 'CLASS 18'
            }, {
                src: '/static/svg/Classes/Floormat.svg',
                name: 'floor mats',
                class: 'CLASS 27'
            }, {
                src: '/static/svg/Classes/Mousepad.svg',
                name: 'mouse pads',
                class: 'CLASS 9'
            }, {
                src: '/static/svg/Classes/Phone.svg',
                name: 'phone covers',
                class: 'CLASS 9'
            }, {
                src: '/static/svg/Classes/Paper.svg',
                name: 'paper goods (stickers, birthday materials)',
                class: 'CLASS 16'
            }, {
                src: '/static/svg/Classes/Key.svg',
                name: 'key chains',
                class: 'CLASS 14'
            }, {
                src: '/static/svg/Classes/Lunch.svg',
                name: 'lunch bag/boxes',
                class: 'CLASS 21'
            }],
            chartOptions: {
                responsive: true
            },
        }
    },
    props: {
        height: {
            type: String,
            default: () => 'auto',
        },
        width: {
            type: String,
            default: () => 'auto',
        },
        min_width: {
            type: String,
            default: () => '500px',
        },
        min_height: {
            type: String,
            default: () => ''    
        },
        max_height: {
            type: String,
            default: () => '210px',
        },
        data: {
            type: Array,
            default: () => [],
        },
        isWith_icon: {
            type: Boolean,
            default: () => false
        },
        isWith_border: {
            type: Boolean,
            default: () => false
        },
        hasStyle: {
            type: Boolean,
            defaul: false
        }
    },
    computed: {
        getData() {
            const data = this.data.length ? this.data : this.mock_data;
            return data.map(item => ({ ...item, classname: this.$t(`${item.name} - ${item.class}`.toLocaleUpperCase()) }));
        }
    }
}
</script>
<template>
    <div
        class="list-container"
        :class="{'withBorder': isWith_border}"
        :style="`height: ${height}; width: ${width}; min-width: ${min_height}; max-height: ${max_height};`"
    >
        <div v-for="(item, index) in getData" :key="index" class="list-item">
            <template v-if="isWith_icon">
                <img :src="item.src" class="list-icon">
            </template>
            <div class="list-data">
                <!-- {{item.name}} - {{item.class}} -->
                <p v-if="!hasStyle">{{ item.classname }}<span v-tooltip="`Number of listings in this class`" class="badge badge-primary badge-pill ml-2">{{ item.count }}</span></p>
                <p v-else class="d-flex justify-content-center align-items-center"><b class="d-block text-primary mr-1">{{item.name}} </b><i class="text-truncate d-block" style="max-width: 250px;">- {{item.class}}</i><span v-tooltip="`Number of listings in this class`" class="badge badge-danger navbar-badge badge-pill ml-2">{{ item.count }}</span></p>
            </div>
        </div>
    </div>
</template>
<style scoped>
.list-container {
    /* display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start; */
    /* gap: 10px; */
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.5em;
    -moz-column-gap: 1.5em;
    column-gap: 1.5em;
    height: auto;
    width: auto;
    text-transform: uppercase;
    padding: 10px 10px 10px 0px;
    font-size: 12px;
    text-align: left;

    /* overflow: auto; */
}
.list-container p {
    margin: 0;
}
.list-container.withBorder {
    padding: 15px;
    border: 1px solid #5153653D;
    border-radius: 12px;
}

/* list item */
.list-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    min-height: 30px;
}

.list-item img {
    width: 20px;
    width: 20px;
}
</style>
