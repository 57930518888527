<script>
  export default {
    name: 'ListCard',
    props: {
      lid: {
        type: [String, Number]
      },
      status: {
        type: [String]
      },
      statusImg: {
        type: [String]
      },
      seller: {
        type: [String]
      },
      campaign: {
        type: [String]
      },
      campaignImg: {
        type: [String]
      },
      researcher: {
        type: [String]
      },
      researcherImg: {
        type: [String]
      },
      listing_title: {
        type: [String]
      },
      listing_url: {
        type: [String]
      },
      error_message: {
        type: [String]
      },
      isHoverable: {
        type: [Boolean],
        default: false
      },
      toggled: {
        type: [Boolean],
        default: false
      },
    }
  }
</script>

<template>
  <div class="ar-list-card position-relative" :class="{'is-hoverable': isHoverable, 'toggled': toggled}">
      <h6 class="text-truncate w-100">{{ seller }}</h6>
      <div class="d-flex align-items-center stuff-container mb-2">
        <span v-if="lid" v-tooltip="`Listing ID`">{{ lid }}</span>
        <span v-if="status" v-tooltip="`Research Status`" class="d-inline-flex justify-content-between align-items-center"><img :src="statusImg" class="status-img mr-1" alt="icon"> <b class="text-truncate">{{ status }}</b></span>
        <span v-if="campaign" v-tooltip="`Campaign`" class="d-inline-flex justify-content-between align-items-center"><img :src="campaignImg" class="campaign-img mr-1" alt="icon"> <b class="text-truncate">{{ campaign }}</b></span>
        <span v-if="researcher" v-tooltip="`Researcher`" class="d-inline-flex justify-content-between align-items-center"><img :src="researcherImg" class="researcher-img mr-1" alt="icon"> <b class="text-truncate">{{ researcher }}</b></span>
      </div>
      <p v-if="listing_title" class="mb-1 line-clamp">{{ listing_title }}</p>
      <a v-if="listing_url" v-tooltip="`Listing URL`" :href="listing_url" target="_blank" class="link text-truncate w-100 d-block"><i class="fas fa-link"></i> {{ listing_url }}</a>
      <p v-if="error_message" v-toolip="`Error Message`" class="mb-0 text-danger link text-truncate w-100 d-block"><i class="fas fa-flag"></i> {{ error_message }}</p>
  </div>
</template>

<style scoped>
  .ar-list-card {
    min-height: 100px;
    border-radius: 8px;
    -webkit-transition: background .2s ease, border-color .5s ease;
    transition: background .2s ease, border-color .5s ease;
    text-align: left;
    border: 1px solid #ccc;
    padding: 10px;
    background: #fff;
  }

  .ar-list-card.is-hoverable:hover {
    background: #f0f0f0;
    cursor: pointer;
  }

  .ar-list-card.toggled {
    background: #f0f0f0;
  }

  h6 {
    margin-bottom: 6px;
    font-size: 18px;
  }

  .stuff-container {
    gap: 3px;
    flex-flow: wrap;
  }

  .stuff-container span {
    display: inline-block;
    border: 1px solid #ccc;
    padding: 1px 5px;
    border-radius: 4px;
    font-size: 12px;
    text-transform: uppercase;
  }

  .stuff-container span img {
    height: 12px;
    width: 12px;
  }

  .stuff-container span b {
    max-width: 85px;
    font-weight: normal;
  }

  .link {
    font-size: 12px;
    font-style: italic;
  }
</style>