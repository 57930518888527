<script>
import { Line as LineChart } from "vue-chartjs/legacy"

export default {
    name: 'Line_Chart',
    components: { LineChart },
    props: {
        chartData: {
            type: Object,
            default: () => {}
        },
        chartOptions: {
            type: Object,
            default: () => {}
        },
        width: {
            type: Number,
            default: 520
        },
        height: {
            type: Number,
            default: 177
        },
        plugins: {
            type: Object,
            default: () => {}
        },
    },
    computed: {
        chartData_computed() {
            let chartData = this.chartData
            return chartData
        },
        chartOptions_computed() {
            let chartOptions = this.chartOptions
            return chartOptions
        }
    }
}
</script>
<template>
    <LineChart
        :chart-data="chartData_computed"
        :chart-options="chartOptions_computed"
        :width="width"
        :height="height"
        :plugins="plugins"
    ></LineChart>
</template>